import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

export class License extends Component {
  render() {
    return (
      <div>
        <Helmet>
            <title>Лицензионное соглашение по использованию текстов и изображений | АкваТехПласт | Волгоград</title>
          </Helmet>
          <div className="container">
            <div className="row">
                <div className="col">
                    <br />
                    <h1>Лицензионное соглашение по использованию текстов и изображений:</h1>
                    <br />
                    <Link to='/'>В начало</Link>
                    <br />
                    <br />
                    <h5>Предоставление прав исключительного использования текстов:</h5>
                    <br />
                    <p> Весь текстовый контент, 
                        предоставленный на сайте <a href="https://fontan34.ru">fontan34.ru</a>, 
                        охраняется авторским правом и является объектом исключительных прав. 
                        Лицензиат получает ограниченное, неисключительное право использования 
                        текстов, при условии, что будет соблюдено обязательное указание источника –  <a href="https://fontan34.ru">fontan34.ru</a>.
                    </p>
                    <br />
                    <h5>Лицензирование изображений:</h5>
                    <br />
                    <p>Изображения, размещенные на сайте, взяты из открытых источников и 
                        подпадают под <a href="https://creativecommons.org/">License</a>. Фотографии также использованы из 
                        фотостока <a href="https://unsplash.com/">Unsplash </a> 
                        и созданы с использованием <a href="https://app.leonardo.ai/">Leonardo AI</a>.
                    </p>
                    <br />
                    <h5>Ответственность за совпадения и случайность:</h5>
                    <br />
                    <p> Любые случайные совпадения в текстовых и графических материалах 
                        сайта рассматриваются как случайные и носителями внезапного и 
                        непредсказуемого характера. Запросы на удаление материалов 
                        должны быть направлены по адресу <a href="mailto:info@fontan34.ru">info@fontan34.ru</a>.
                    </p>
                    <br />
                    <h5>Применение законодательства:</h5>
                    <br />
                    <p> Настоящее лицензионное соглашение подчиняется законодательству 
                        Российской Федерации, включая, но не ограничиваясь, 
                        статьи 1259, 1270 Гражданского кодекса
                         РФ и другие применимые законы.
                    </p>
                    <br />
                    <h5>Ограничение прав исключительного использования:</h5>
                    <br />
                    <p>Лицензиат не вправе передавать, продавать, сублицензировать 
                        или иным образом распоряжаться исключительными правами 
                        без предварительного письменного согласия ООО АкваТехПласт.
                    </p>
                    <br />
                    <h5>Обязательства Лицензиата:</h5>
                    <br />
                    <p>Лицензиат обязуется использовать материалы в соответствии 
                        с применимым законодательством и не предпринимать действий, 
                        противоречащих данному лицензионному соглашению.
                        Удалить информацию с сайта по письменному уведомлению правообладателя контента с
                        предоставлением доказательств подтверждающих исключительное право на размещенную информацию.
                    </p>
                    <br />
                    <h5>Ответственность:</h5>
                    <br />
                    <p>ООО АкваТехПласт не несет ответственности за возможные убытки, прямые или косвенные, возникшие в связи с использованием материалов.</p>
                    <h6>28.01.2024г. ООО "АкваТехПласт"</h6>
                </div>
            </div>
          </div>
      </div>
    )
  }
}

export default License