import React, { Component } from 'react'
import { GiWaterFountain } from "react-icons/gi";
import { CiCircleCheck } from "react-icons/ci";
import cl from '../style/Style.module.css'
import Footer from '../../../modules/footer/Footer';

const Chef = () => {

    const shereSvg = `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.dev/svgjs" viewBox="0 0 800 800"><defs><radialGradient id="sssurface-grad-dark" r="75%" cx="20%" cy="20%">
    <stop offset="0%" stop-color="hsla(36, 98%, 51%, 0.36)" stop-opacity="0"></stop>
    <stop offset="100%" stop-color="#c46c005c" stop-opacity="0.14"></stop>
  </radialGradient><radialGradient id="sssurface-grad-light" r="53%" cx="33%" cy="30%">
  <stop offset="0%" stop-color="#ffff7d5c" stop-opacity="0.75"></stop>
  <stop offset="100%" stop-color="hsla(36, 98%, 51%, 0.36)" stop-opacity="0"></stop>
</radialGradient></defs><g><circle r="350" cx="400" cy="400" fill="hsla(36, 98%, 51%, 0.36)"></circle><circle r="350" cx="400" cy="400" fill="url(#sssurface-grad-dark)"></circle><circle r="350" cx="400" cy="400" fill="url(#sssurface-grad-light)"></circle></g></svg>`;

    return (
      <div className={cl.block}>
        <h2>Шеф-Монтаж Фонтанов</h2>
        <h3>Наш подход к работе заключается в 
            предоставлении максимального набора 
            услуг для наших клиентов. Одним из ключевых 
            моментов является гибкость выбора услуг клиентом. 
            Шеф-монтаж, в контексте нашей работы, 
            представляет собой два варианта. В 
            первом случае, наш специалист осуществляет 
            надзор над строительной частью и сборкой 
            оборудования, что позволяет существенно 
            сэкономить средства заказчика. Во втором 
            варианте, шеф-монтаж включает в себя 
            надзор за строительной частью и 
            последующий монтаж оборудования 
            силами нашей команды
        </h3>
            <div className={cl.logotype}>
                <div className={cl.logotype__line}></div>
                <div className={cl.logotype__icon}>
                    <GiWaterFountain />
                </div>
                <div className={cl.logotype__line}></div>
            </div>
            <h4>От Идеи до Реальности:</h4>
            <p>Наша компания предлагает полный цикл 
                услуг по строительству фонтанов, 
                включая визуализацию, проектирование, 
                шеф-монтаж, монтаж и поставку оборудования. 
                Мы специализируемся на использовании
                высококачественного оборудования от 
                ведущей немецкой марки OASE, обеспечивая 
                оптимальное соотношение цены и качества. 
                Оборудование OASE предлагает широкий 
                спектр возможностей, включая управляемые 
                насадки, насосы и инновационные элементы 
                подсветки.
            </p>
            <div className={cl.block__block}>
                <div className={cl.block__block__text}>
                    <h4>Почему следует довериться нам:</h4>
                    <ul>
                        <span>Использование Лучшего Оборудования</span>
                        <CiCircleCheck />
                        <li>Мы оснащаем наши фонтаны 
                            высококачественным оборудованием от 
                            OASE, обеспечивая надежность и эффективность.
                        </li>
                        <span>Гибкий Подход к Услугам</span>
                        <CiCircleCheck />
                        <li>Предоставляем клиентам гибкий 
                            выбор услуг, адаптируясь к их 
                            потребностям и финансовым 
                            возможностям
                        </li>
                        <span>Экономия Финансов</span>
                        <CiCircleCheck />
                        <li>
                            Шеф-монтаж, как вариант, 
                            позволяет клиентам экономить, 
                            сохраняя высокий стандарт 
                            профессионального надзора и
                            монтажа.
                        </li>
                    </ul>
                </div>
                <div className={cl.block__block__svg}>
                    <div dangerouslySetInnerHTML={{__html : shereSvg}}></div>
                </div>
            </div>
            <div className={cl.logotype}>
                <div className={cl.logotype__line}></div>
                <div className={cl.logotype__icon}>
                    <GiWaterFountain />
                </div>
                <div className={cl.logotype__line}></div>
            </div>
            <p>Сотрудничество с нами обеспечивает
                не только профессиональное выполнение 
                работ, но и гибкий выбор услуг в соответствии с 
                вашими потребностями. Выберите нас для качественного 
                шеф-монтажа, где эффективность и техническая грамотность 
                являются ключевыми принципами нашей работы.
            </p>
            <div className={cl.footer}>

                    <div className={cl.footer__left}>
                        <div className={cl.circle} dangerouslySetInnerHTML={{__html : shereSvg}}></div>
                    <div className={cl.footer__left__1}>
                            <img src="image/services/footer/g5.jpg" alt="Фонтан" />
                        </div>
                        <div className={cl.footer__left__2}>
                            <img src="image/services/footer/v9.jpg" alt="Красивый фонтан" />
                            <img src="image/services/footer/v10.jpg" alt="фонтан" />
                        </div>
                    </div>
                    <div className={cl.footer__right}>
                        <Footer />
                    </div>
                </div>
      </div>
    )
  
}

export default Chef

