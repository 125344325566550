import React from 'react'
import { TbFountain } from "react-icons/tb";
import { Link } from 'react-router-dom';
import cl from './Navigation.module.css'

const Navigation = () => {

    return (
      <nav className={cl.navigation}>
        <ul className={cl.navigation__list}>
            <li><Link to='/services'>
                <div className={cl.navigation__list__block}>
                    <TbFountain/>
                    <p>Услуги и цены</p>
                </div>
            </Link></li>
            <li><Link to='/type'>
                <div className={cl.navigation__list__block}>
                    <TbFountain/>
                    <p>Типы фонтанов</p>
                </div>
            </Link></li>
            <li><Link to='/gallery'>
                <div className={cl.navigation__list__block}>
                    <TbFountain/>
                    <p>Галерея</p>
                </div>
            </Link></li>
            <li><Link to='/contact'>
                <div className={cl.navigation__list__block}>
                    <TbFountain/>
                    <p>Контакты</p>
                </div>
            </Link></li>
        </ul>
      </nav>
    )
  
}

export default Navigation
