import React, { useState } from 'react'
import cl from './ContactWiget.module.css'
import { BiLogoTelegram, BiLogoWhatsapp, BiPhone, BiChat } from "react-icons/bi";
import { SlSocialVkontakte } from "react-icons/sl";
import { IoMdMailOpen } from "react-icons/io";


const ContactWiget = () => {

  const [isClick, setIsClick] = useState(false)

  const widgetClick = () => {
      setIsClick(!isClick)
  }

  const windowClasses = [cl.widget__window];
    if(isClick){
      windowClasses.push(cl.active)
    }

    return (
      <div  onClick={widgetClick} className={cl.widget}>
        <div className={cl.widget__one}></div>
        <div className={cl.widget__there}></div>
        <div className={cl.widget__two}></div>
        <div className={cl.widget__icons}>
          <BiPhone className={cl.icons} />
          <IoMdMailOpen className={cl.icons1} />
          <BiLogoTelegram className={cl.icons2} />
          <BiLogoWhatsapp className={cl.icons3} />
          <SlSocialVkontakte className={cl.icons4} />
          <div className={windowClasses.join(' ')}>
              <ul  className={cl.widget__list}>
                <li><a href="tel:+79023815963"><BiPhone className={cl.window__icons} /></a></li>
                <li><a href="https://wa.me/79023815963"  target='blank'><BiLogoWhatsapp className={cl.window__icons} /></a></li>
                <li><a href="https://t.me/fontan_atp"><BiLogoTelegram className={cl.window__icons} /></a></li>
                <li><a href="mailto:info@fontan34.ru"><IoMdMailOpen className={cl.window__icons} /></a></li>
              </ul>
          </div>
        </div>
        
      </div>
    )
  
}

export default ContactWiget 
