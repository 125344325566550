import React, {useState} from 'react'
import Header from '../../../modules/header/Header'
import cl from '../style/Style.module.css'
import { CiCircleCheck } from "react-icons/ci";
import { GiWaterFountain } from "react-icons/gi";
import Footer from '../../../modules/footer/Footer';
import AnimateFade from '../../../UI/animateFade/AnimateFade'
import { ModalWindow } from '../../../UI/modalWindow/ModalWindow';
import { Helmet } from 'react-helmet';

const Interactive = () => {

    const [isOpenModal, setIsOpenModal] = useState(false)

    return (
        <div className={cl.main}>
        <Helmet>
            <title>Интерактивные фонтаны | АкваТехПласт | Волгоград</title>
            <meta name="keywords" content="Интерактивные фонтаны, проектирование, технологии управления водой, гидравлические системы, инновации, водные элементы, архитектурная гидравлика, дизайн, инженерия, современные фонтаны, водные инсталляции." data-react-helmet="true" />
            <meta name="description" content="интерактивные фонтаны: профессиональное проектирование, технологии управления водой, точные гидравлические системы и инновационные решения для водных элементов." />
        </Helmet>
            <div className={cl.main__left}>
                <AnimateFade />
                <div className={cl.main__left__title}>
                    <h1>Интерактивные фонтаны</h1>
                    
                </div>
            </div>
            <div className={cl.main__right}>
                <AnimateFade />
                <Header />
                <div className={cl.main__right__top}>
                <div className={cl.main__right__top__left}>
                    <h2>Интерактивные фонтаны – это современное искусство воды, где 
                        технологии соединяются с природой, чтобы создать 
                        захватывающие визуальные и звуковые впечатления. 
                        </h2>
                    <h3>Интерактивные фонтаны – это не только технически сложные 
                        сооружения, но и источник визуальных и звуковых удовольствий. 
                        Мы готовы поднять ваши водные развлечения на новый 
                        уровень, создавая инновационные и увлекательные водные шоу.</h3>
                        <button className={cl.modalbtn} onClick={() => setIsOpenModal(true)}>Оставить заявку</button>
                </div>
                <div className={cl.main__right__top__right}>
                    <img src="/image/type/interactive/003.jpg" alt="Сухой фонтан ночью" />
                </div>
                </div>
                <div className={cl.main__right__bottom__top}>
                    <div className={cl.main__right__bottom__top__line}></div>
                    <div className={cl.main__right__bottom__top__icon}>
                        <GiWaterFountain />
                    </div>
                    <div className={cl.main__right__bottom__top__line}></div>
                </div>
                <div className={cl.main__right__center}>
                    <div className={cl.main__right__center__left}>
                        <img src="/image/type/interactive/002.jpg" alt="Сухой фонтан ночью" />
                    </div>
                    <div className={cl.main__right__center__center}>
                        
                        <ul>
                            <li>
                                <CiCircleCheck />
                                <span>Танцующие Фонтаны:</span>
                                <p>Реагирующие на музыку и создают танцевальные струи воды в ритме композиции.</p>
                            </li>
                            <li>
                                <CiCircleCheck />
                                <span>Интерактивные Дисплеи:</span>
                                <p>Использующие светодиодные технологии для создания световых образов</p>
                            </li>
                            <li>
                                <CiCircleCheck />
                                <span> Увлекательный Видовой Эффект:</span>
                                <p>создают уникальные и увлекательные зрелища, привлекая внимание и создавая атмосферу развлечения.</p>
                            </li>
                            <li>
                                <CiCircleCheck />
                                <span>Интерактивное Взаимодействие:</span>
                                <p>Возможность взаимодействия зрителей с фонтаном, делая пребывание вокруг него еще более увлекательным.</p>
                            </li>
                        </ul>
                    </div>
                    <div className={cl.main__right__center__right}>
                        <img src="/image/type/interactive/009.jpg" alt="Сухой фонтан ночью" />
                    </div>
                </div>
                <div className={cl.main__right__bottom}>
                    <div className={cl.main__right__bottom__top}>
                        <div className={cl.main__right__bottom__top__line}></div>
                        <div className={cl.main__right__bottom__top__icon}>
                            <GiWaterFountain />
                        </div>
                        <div className={cl.main__right__bottom__top__line}></div>
                    </div>
                    <div className={cl.main__right__bottom__center}>
                        <h5>Наша компания предоставляет гарантию высокого 
                            стандарта качества в каждом проекте. 
                            Наш опыт в управлении строительством и 
                            инновационные методы обеспечивают 
                            эффективное и безопасное завершение каждого этапа.</h5>
                        <p>При строительстве интерактивных фонтанов наша команда начинает с 
                            тщательного инженерного проектирования. Мы уделяем особое 
                            внимание подготовке места, учитывая географические 
                            особенности и структурные требования. Наши 
                            инженеры имеют обширный опыт в создании 
                            устойчивых конструкций с использованием 
                            передовых методов и материалов.</p>  
                        <p>Мы активно используем передовые технологии для создания 
                            интерактивных фонтанов. Светодиодные системы освещения, 
                            сенсорные технологии и программное обеспечение для 
                            синхронизации с музыкальными композициями - 
                            все это входит в наше технологическое вооружение. 
                            Интегрированные системы управления обеспечивают 
                            точное и гармоничное функционирование фонтанов.
                            Мы следим за инновациями в отрасли и внедряем передовые 
                            технологии для достижения максимальной 
                            эффективности в работе фонтанов. 
                            Наш подход к техническим решениям 
                            гарантирует, что каждый проект оснащен 
                            современными возможностями.</p>
                        <p>Строительство интерактивных фонтанов требует высокого 
                            стандарта качества и строгого управления проектом. 
                            Мы уделяем внимание каждой детали, начиная от выбора 
                            материалов до инсталляции оборудования. 
                            Процесс управления проектом включает в 
                            себя регулярные проверки, чтобы 
                            гарантировать соблюдение сроков и бюджета.</p> 
                        <p>Наша компания предоставляет гарантию высокого 
                            стандарта качества в каждом проекте. 
                            Наш опыт в управлении строительством и 
                            инновационные методы обеспечивают 
                            эффективное и безопасное завершение каждого этапа.</p> 
                            <button className={cl.modalbtn} onClick={() => setIsOpenModal(true)}>Оставить заявку</button>     
                    </div>
                    <div className={cl.main__right__bottom__bottom}>
                        <div className={cl.main__right__bottom__bottom__img}>
                            <div className={cl.img}>
                                <div className={cl.img__left}>
                                    <img src="/image/type/interactive/004.jpg" alt="Каскадные фонтаны" />
                                </div>
                                <div className={cl.img__right}>
                                    <img src="/image/type/interactive/007.jpg" alt="Каскадные фонтаны" />
                                    <img src="/image/type/interactive/001.jpg" alt="Каскадные фонтаны" />
                                </div>
                            </div>
                            <div className={cl.img}>
                                <div className={cl.img__left}>
                                    <img src="/image/type/interactive/006.jpg" alt="Каскадные фонтаны" />
                                </div>
                                <div className={cl.img__right}>
                                    <img src="/image/type/interactive/005.jpg" alt="Каскадные фонтаны" />
                                    <img src="/image/type/interactive/010.jpg" alt="Каскадные фонтаны" />
                                </div>
                            </div>
                        </div>
                            
                        <div className={cl.main__right__bottom__bottom__footer}>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
            <ModalWindow 
            isOpen={isOpenModal}
            onClose={() => setIsOpenModal(false)}
         />
        </div>
    )
}

export default Interactive