import React, { useState, useEffect } from 'react'
import ReactImageGallery from 'react-image-gallery'
import "react-image-gallery/styles/css/image-gallery.css";


const images = [
  {
    original : 'image/gallery/gallery/1.jpg',
    thumbnail: 'image/gallery/gallery/1.jpg',
    originalHeight: '670px',
    
  },
  {
    original : 'image/gallery/gallery/2.jpg',
    thumbnail: 'image/gallery/gallery/2.jpg',
    originalHeight: '670px',
    
  },
  {
    original : 'image/gallery/gallery/3.jpg',
    thumbnail: 'image/gallery/gallery/3.jpg',
    originalHeight: '670px',
    
  },
  {
    original : 'image/gallery/gallery/4.jpg',
    thumbnail: 'image/gallery/gallery/4.jpg',
    originalHeight: '670px',
    
  },
  {
    original : 'image/gallery/gallery/5.jpg',
    thumbnail: 'image/gallery/gallery/5.jpg',
    originalHeight: '670px',
    
  },
  {
    original : 'image/gallery/gallery/6.jpg',
    thumbnail: 'image/gallery/gallery/6.jpg',
    originalHeight: '670px',
    
  },
  {
    original : 'image/gallery/gallery/7.jpg',
    thumbnail: 'image/gallery/gallery/7.jpg',
    originalHeight: '670px',
    
  },
  {
    original : 'image/gallery/gallery/8.jpg',
    thumbnail: 'image/gallery/gallery/8.jpg',
    originalHeight: '670px',
    
  },
  {
    original : 'image/gallery/gallery/9.jpg',
    thumbnail: 'image/gallery/gallery/9.jpg',
    originalHeight: '670px',
    
  },
  {
    original : 'image/gallery/gallery/10.jpg',
    thumbnail: 'image/gallery/gallery/10.jpg',
    originalHeight: '670px',
    
  },

  
]

const GalleryImage = () => {

const [screenWidth, setScreenWidth] = useState(window.innerWidth)

const handleResize = () => {
  setScreenWidth(window.innerWidth);
};

useEffect(() => {
  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);

const isWideScreen = screenWidth > 1200;

const positionSlide = isWideScreen
  ? 'left'
  : 'bottom';

const showTrumbnails = isWideScreen 
  ? false
  : true;

  return (
      <div>
        <ReactImageGallery
          showNav={true}
          thumbnailPosition={positionSlide}
          showPlayButton={false}
          autoPlay={true}
          disableSwipe={true}
          disableThumbnailSwipe={true}
          showThumbnails={true}
          showBullets={showTrumbnails}
          items={images}
          />
      </div>
    )
}

export default GalleryImage