import React, { useEffect} from 'react'
import cl from './Contact.module.css'
import Header from '../../modules/header/Header'
import { PiTelegramLogoThin, PiPhoneThin, PiAtThin, PiWhatsappLogoThin  } from "react-icons/pi";
import { GiWaterFountain } from "react-icons/gi";
import { Link } from 'react-router-dom';
import AnimateFade from '../../UI/animateFade/AnimateFade';
import { Helmet } from 'react-helmet';

const Contact = () => {



    return (
      <section className={cl.contact}>
            <Helmet>
                <title>Контакты | АкваТехПласт | Волгоград</title>
                <meta name="keywords" content="Строительство фонтанов, проектирование, контакты, техническое обслуживание, инженерия, дизайн, эффективность, надежность, высокое качество, архитектура, водные инсталляции, консультации" data-react-helmet="true" />
                <meta name="description" content="Свяжитесь с нами для профессионального строительства фонтанов. Эффективное проектирование, высококачественное строительство и надежное техническое обслуживание. " />
            </Helmet>
        <div className={cl.contact__left}>
            <AnimateFade />
        </div>
        <div className={cl.contact__right}>
            <AnimateFade />
           <Header /> 
           <div className={cl.contact__right__body}>
                <div className={cl.contact__right__body__left}>
                    <div className={cl.line}>
                        <div className={cl.line__line}></div>
                        <div className={cl.line__icon}><GiWaterFountain /></div>
                        <div className={cl.line__line}></div>
                    </div>
                    <div className={cl.contact__right__body__left__title}>
                        <h1>АкваТехПласт</h1>
                        <h2>Строительство фонтанов</h2>
                    </div>
                    <div className={cl.contact__right__body__left__icon}>
                        <a href="tel:+79023815963">
                            <PiPhoneThin />
                        </a>
                        <a href="https://wa.me/79023815963">
                            <PiWhatsappLogoThin />
                        </a>
                        <a href="mailto:info@fontan34.ru"  target='blank'>
                            <PiAtThin/>
                        </a>
                        <a href="https://t.me/fontan_atp">
                            <PiTelegramLogoThin/>
                        </a>
                    </div>
                    <div className={cl.contact__right__body__left__inn}>
                        <h5>ООО «АкваТехПласт»</h5>
                        <p>ИНН 3460006945, КПП 346001001</p>
                        <p>ОГРН 1133443009842, ОКПО 13725807</p>
                        <Link to='/info'>Условия сбора и хранения персональных данных</Link>
                        <Link to='/license'>Лицензионное соглашение</Link>
                    </div>
                    


                </div>
                <div className={cl.contact__right__body__right}>
                    <div className={cl.contact__right__body__right__address}>
                        <h6>Наш адрес:</h6>
                        <p>400119 г. Волгоград</p>
                        <p>ул. 25 лет Октября, 1, стр. 105 (этаж 2)</p>
                        <p>тел. отдела продаж: <a href="tel:+79023815963"> +7(902)381-59-63</a></p>
                        <p>e-mail: <a href="mailto:info@fontan34.ru">info@fontan34.ru</a></p>
                        
                    </div>
                    <div className={cl.contact__right__body__right__map}>
                        <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A70027287a60a9be1be209e830d113d9b5c1f7b4dfc955152f201800ebdac8e1c&amp;source=constructor" width="100%" height="390" frameborder="0"></iframe>
                    </div>
                </div>
           </div>
        </div>
      </section>
    )
  
}

export default Contact