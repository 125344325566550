import React from 'react'
import SocialIcon from '../../UI/socialIcon/SocialIcon'
import cl from './Footer.module.css'
import { Link } from 'react-router-dom'
import { GiWaterFountain } from "react-icons/gi";

const Footer = () => {

    const currentYear = new Date().getFullYear();
    
    return (
      <footer>
        <SocialIcon />
            <div className={cl.nav}>
                <GiWaterFountain />
                <div className={cl.nav__top}>
                    
                    <div className={cl.nav__top__list}>
                        <ul>
                            <li><Link to='/'>На главную</Link></li>
                            <li><Link to='/services'>Услуги и цены</Link></li>
                            <li><Link to='/gallery'>Галерея</Link></li>
                            <li><Link to='/contact'>Контакты</Link></li>
                        </ul>
                    </div>
                    <div className={cl.nav__top__list}>
                        <h6>Типы фонтанов</h6>
                        <ul>
                            <li><Link to='/type/pedestrian'>Пешеходный</Link></li>
                            <li><Link to='/type/sculptural'>Скульптурный</Link></li>
                            <li><Link to='/type/cascade'>Каскадный</Link></li>
                            <li><Link to='/type/interactive'>Интерактивный</Link></li>
                        </ul>
                    </div>
                </div>
                <GiWaterFountain />
                <div className={cl.nav__address}>
                    <p>ООО «АкваТехПласт»</p>
                     <p>ИНН 3460006945, КПП 346001001</p>
                    <p>ОГРН 1133443009842, ОКПО 13725807</p>
                    <a href="https://build-root.ru" target='blank'>Разработано "b_root" &copy; {currentYear}</a>
                </div>
            </div>
      </footer>
    )
  
}

export default Footer