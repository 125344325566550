import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import cl  from './BreadScrumbs.module.css'

const routeNames = {
  '/': 'Главная',
  '/type': 'Типы фонтанов',
  '/maps': 'Карта объектов',
  '/diplomas' : 'Дипломы и сертификаты',
  '/type/pedestrian' : 'Пешеходные фонтаны',
  '/type/sculptural' : 'Скульптурные фонтаны',
  '/type/cascade' : 'Каскадные фонтаны',
  '/type/interactive' : 'Интерактивные фонтаны',
  '/contact' : 'Контакты',
  '/services' : 'Услуги и цены',
  '/gallery' : 'Глерея изображений',
};

const Breadcrumbs = () => {
  const location = useLocation();
  const paths = location.pathname.split('/').filter((path) => path !== '');

  return (
    <div className={cl.bs}>
      <Link className={cl.bs__link} to="/">На главную</Link>
      {paths.map((path, index) => {
        const routeTo = `/${paths.slice(0, index + 1).join('/')}`;
        const isLast = index === paths.length - 1;
        const displayName = routeNames[routeTo] || path; // Используем имя из объекта, если есть

        return (
          <span key={path}>
            <span className={cl.bs__link} > &nbsp;/&nbsp; </span>
            {isLast ? (
              <span className={cl.bs__link__now} >{displayName}</span>
            ) : (
              <Link to={routeTo}>{displayName}</Link>
            )}
            
          </span>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;


