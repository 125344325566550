import React, {useState} from 'react'
import cl from './Services.module.css'
import Header from '../../modules/header/Header'
import Visual from './visual/Visual';
import Consulting from './consulting/Сonsulting';
import Desing from './desing/Desing';
import Build from './build/Build';
import Chef from './chef/Chef';
import AnimationFade from '../../UI/animateFade/AnimateFade'
import { ModalWindow } from '../../UI/modalWindow/ModalWindow';
import { Helmet } from 'react-helmet';

const Services = () => {

    const [isOpenModal, setIsOpenModal] = useState(false)
    const [activeBox, setActiveBox] = useState(1)

    const handleClick = (boxNumber) => {
        setActiveBox(boxNumber === activeBox ? null : boxNumber);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
      };

    return (
        <section className={`${cl.main}`}>
            <Helmet>
                <title>Услуги и цены | АкваТехПласт | Волгоград</title>
                <meta name="keywords" content="Проектирование, строительство, консультирование, шеф-монтаж, фонтаны, водные инсталляции, инженерия, дизайн, гидравлика, техническое обслуживание, архитектурная интеграция, устойчивость." data-react-helmet="true" />
                <meta name="description" content="Проектируем и строим фонтаны с полным циклом услуг: консультирование, профессиональное проектирование и строительство с использованием шеф-монтажа." />
            </Helmet>
            <div className={cl.main__left}>
                <AnimationFade />
                <div className={cl.main__left__title}>
                    <h1>Услуги и цены</h1>
                </div>
                <div className={cl.main__left__right}>
                <div className={`${cl.box1} ${activeBox === 1 ? cl.active : ''}`} 
                    onClick={() => handleClick(1)}>
                        <div className={cl.front}>
                            <h3>Консультирование</h3>
                        </div>
                    </div>
                    <div className={`${cl.box2} ${activeBox === 2 ? cl.active : ''}`} 
                    onClick={() => handleClick(2)}>
                        <div className={cl.front}>
                            <h3>Визуализация</h3>
                        </div>
                    </div>
                    <div className={`${cl.box3} ${activeBox === 3 ? cl.active : ''}`} 
                    onClick={() => handleClick(3)}>
                        <div className={cl.front}>
                            <h3>Проектирование</h3>
                        </div>
                    </div>
                    <div className={`${cl.box4} ${activeBox === 4 ? cl.active : ''}`} 
                    onClick={() => handleClick(4)}>
                        <div className={cl.front}>
                            <h3>Строительство</h3>
                        </div>
                    </div>
                    <div className={`${cl.box5} ${activeBox === 5 ? cl.active : ''}`} 
                    onClick={() => handleClick(5)}>
                        <div className={cl.front}>
                            <h3>Шеф-монтаж</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className={cl.main__right}>
                <AnimationFade />
                <Header />
                <div className={cl.media}>
                    <ul>
                        <li onClick={() => handleClick(1)}>Консультирование</li>
                        <li onClick={() => handleClick(2)}>Визуализация</li>
                        <li onClick={() => handleClick(3)}>Проектирование</li>
                        <li onClick={() => handleClick(4)}>Строительство</li>
                        <li onClick={() => handleClick(5)}>Шеф-Монтаж</li>
                    </ul>
                </div>
                <div className={cl.main__right__btn}>
                    <button className={cl.modalbtn} onClick={() => setIsOpenModal(true)}>Оставить заявку</button>
                </div>
                <div className={cl.main__right__box}>
                <div className={`${cl.text} ${activeBox === 1 ? cl.fade : ''}`} >
                       <Consulting />
                    </div>
                    <div className={`${cl.text} ${activeBox === 2 ? cl.fade : ''}`} >
                        <Visual />
                    </div>
                    <div className={`${cl.text} ${activeBox === 3 ? cl.fade : ''}`} >
                        <Desing />
                    </div>
                    <div className={`${cl.text} ${activeBox === 4 ? cl.fade : ''}`} >
                        <Build />
                    </div>
                    <div className={`${cl.text} ${activeBox === 5 ? cl.fade : ''}`} >
                        <Chef />
                    </div>
                    
                </div>
                
                <ModalWindow 
            isOpen={isOpenModal}
            onClose={() => setIsOpenModal(false)}
         />
            </div>
        </section>
    )
  
}

export default Services
