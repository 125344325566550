import React, { useState, useEffect } from 'react';
import cl from './Gallery.module.css';
import AnimateFade from '../../UI/animateFade/AnimateFade';
import Header from '../../modules/header/Header';
import Footer from '../../modules/footer/Footer';
import { ModalWindow } from '../../UI/modalWindow/ModalWindow';
import { Helmet } from 'react-helmet';
import GalleryImage from '../../UI/galleryImage/GalleryImage';
import { GiWaterFountain } from "react-icons/gi";




const Gallery = () => {
  
  const shereSvg = `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.dev/svgjs" viewBox="0 0 800 800"><defs><radialGradient id="sssurface-grad-dark" r="75%" cx="20%" cy="20%">
  <stop offset="0%" stop-color="hsla(36, 98%, 51%, 0.36)" stop-opacity="0"></stop>
  <stop offset="100%" stop-color="#c46c005c" stop-opacity="0.14"></stop>
</radialGradient><radialGradient id="sssurface-grad-light" r="53%" cx="33%" cy="30%">
<stop offset="0%" stop-color="#ffff7d5c" stop-opacity="0.75"></stop>
<stop offset="100%" stop-color="hsla(36, 98%, 51%, 0.36)" stop-opacity="0"></stop>
</radialGradient></defs><g><circle r="350" cx="400" cy="400" fill="hsla(36, 98%, 51%, 0.36)"></circle><circle r="350" cx="400" cy="400" fill="url(#sssurface-grad-dark)"></circle><circle r="350" cx="400" cy="400" fill="url(#sssurface-grad-light)"></circle></g></svg>`;



  const [isOpenModal, setIsOpenModal] = useState(false);


  return (
    <section className={cl.body}>
      <AnimateFade />
      <Helmet>
        <title>Галерея фотографий | АкваТехПласт | Волгоград</title>
        <meta name="keywords" content="..." />
        <meta name="description" content="..." />
      </Helmet>
      <div className={cl.body__left}>
        <AnimateFade />
        <div className={cl.body__left__title}>
          <h1>Фотографии фонтанов</h1>
        </div>
      </div>
      <div className={cl.body__right}>
        <Header />
        

        <div className={cl.gallery}>
          <button className={cl.modalbtn} onClick={() => setIsOpenModal(true)}>
            Рассчитать стоимость
          </button>
          <div id='images' className={cl.gallery__body}>
            <h2>Наша компания специализируется на качественном строительстве фонтанов, 
              объединяя инновационные технологии и искусство ландшафтного дизайна. 
              От элегантных городских фонтанов до уникальных архитектурных водных композиций. 
              Каждый проект – это результат нашего профессионализма и стремления
               к совершенству. Мы создаем водные объекты, которые не только 
               впечатляют своей красотой, но и соответствуют самым высоким 
               стандартам безопасности и эффективности. Обратитесь к нам, 
               чтобы превратить вашу идею в водный шедевр</h2>
               <div className={cl.logotype}>
                <div className={cl.logotype__line}></div>
                <div className={cl.logotype__icon}>
                    <GiWaterFountain />
                </div>
                <div className={cl.logotype__line}></div>
            </div>
             <div className={cl.gallery__body__swiper}>
              <GalleryImage />
             </div>
          </div>
          <div className={cl.logotype}>
                <div className={cl.logotype__line}></div>
                <div className={cl.logotype__icon}>
                    <GiWaterFountain />
                </div>
                <div className={cl.logotype__line}></div>
            </div>
        </div>
        
        <div className={cl.footer}>
          <div className={cl.footer__left}>
            <div
              className={cl.circle}
              dangerouslySetInnerHTML={{ __html: shereSvg }}
            ></div>
          </div>
          <div className={cl.footer__right}>
            <Footer />
          </div>
        </div>
      </div>

      <ModalWindow isOpen={isOpenModal} onClose={() => setIsOpenModal(false)} />
    </section>
  );
};

export default Gallery;
