import React, { Component } from 'react'
import { GiWaterFountain } from "react-icons/gi";
import { CiCircleCheck } from "react-icons/ci";
import cl from '../style/Style.module.css'
import Footer from '../../../modules/footer/Footer';

const Visual = () => {

    const shereSvg = `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.dev/svgjs" viewBox="0 0 800 800"><defs><radialGradient id="sssurface-grad-dark" r="75%" cx="20%" cy="20%">
    <stop offset="0%" stop-color="hsla(36, 98%, 51%, 0.36)" stop-opacity="0"></stop>
    <stop offset="100%" stop-color="#c46c005c" stop-opacity="0.14"></stop>
  </radialGradient><radialGradient id="sssurface-grad-light" r="53%" cx="33%" cy="30%">
  <stop offset="0%" stop-color="#ffff7d5c" stop-opacity="0.75"></stop>
  <stop offset="100%" stop-color="hsla(36, 98%, 51%, 0.36)" stop-opacity="0"></stop>
</radialGradient></defs><g><circle r="350" cx="400" cy="400" fill="hsla(36, 98%, 51%, 0.36)"></circle><circle r="350" cx="400" cy="400" fill="url(#sssurface-grad-dark)"></circle><circle r="350" cx="400" cy="400" fill="url(#sssurface-grad-light)"></circle></g></svg>`;


    return (
      <div className={cl.block}>
        <h2>Профессиональная Визуализация Фонтанов</h2>
        <h3>Наша компания применяет специализированные 
            программы для создания инновационных 
            музыкальных фонтанных шоу. Начиная с 
            простых композиций и заканчивая 
            полностью анимированными мультимедийными 
            проектами, мы предоставляем креативные 
            решения для воплощения идей клиентов. 
            Мы также разрабатываем концепции и оказываем 
            поддержку с начального этапа проектирования до 
            выбора оптимальной музыки для фонтанного 
            представления.
        </h3>
        <div className={cl.logotype}>
            <div className={cl.logotype__line}></div>
            <div className={cl.logotype__icon}>
                <GiWaterFountain />
            </div>
            <div className={cl.logotype__line}></div>
        </div>
        <h4>От Идеи до Реальности:</h4>
            <p>Любой проект начинается с концепции, 
                и мы гарантируем его воплощение в 
                реальности. Наши программы визуализации 
                помогают клиентам увидеть каждую деталь 
                будущего фонтанного шоу, обеспечивая 
                более четкое понимание и возможность 
                внесения корректив на ранних стадиях.
                Наша система предоставляет неограниченные 
                возможности для творчества, объединяя 
                водные элементы, лазерное шоу, световые 
                эффекты и пиротехнику. Наш опыт в фонтанной 
                отрасли поможет вам подобрать оптимальные 
                компоненты системы и создать уникальные 
                водные образы.
            </p>
            <div className={cl.block__block}>
                <div className={cl.block__block__text}>
                    <h4>Почему следует довериться нам:</h4>
                    <ul>
                        <span>Профессиональное Консультирование</span>
                        <CiCircleCheck />
                        <li>Наши эксперты с радостью делятся 
                            своим опытом и рекомендациями по 
                            оптимизации фонтанных систем, 
                            обеспечивая клиентов необходимой 
                            информацией для принятия осознанных 
                            решений.
                        </li>
                        <span>Технологическая Инновация</span>
                        <CiCircleCheck />
                        <li>Мы внедряем инновационные 
                            решения и используем передовые 
                            технологии, чтобы каждый проект 
                            был на гребне волны современных 
                            технологических достижений.
                        </li>
                        <span>Индивидуальный Подход</span>
                        <CiCircleCheck />
                        <li>С учетом специфики места, 
                            бюджета и эстетических предпочтений, 
                            мы разрабатываем персонализированные 
                            решения, чтобы каждый фонтан стал 
                            уникальным произведением искусства.
                        </li>
                    </ul>
                </div>
                <div className={cl.block__block__svg}>
                    <div dangerouslySetInnerHTML={{__html : shereSvg}}></div>
                </div>
            </div>
            <div className={cl.logotype}>
                <div className={cl.logotype__line}></div>
                <div className={cl.logotype__icon}>
                    <GiWaterFountain />
                </div>
                <div className={cl.logotype__line}></div>
            </div>
            <p>Сотрудничество с нашей компанией в области 
                создания фонтанных шоу обещает вам не 
                только креативные и впечатляющие визуализации, 
                но и профессиональное руководство от идеи до 
                воплощения в реальность. Наш опыт в фонтанных 
                технологиях, специализированные программы, 
                бесконечные технические возможности и 
                индивидуальный подход делают нас надежным 
                партнером для тех, кто стремится к уникальным 
                и неповторимым фонтанным представлениям. 
            </p>
            <p>Обращаясь к нам, вы получаете 
                не только визуализацию, но и 
                полноценное сопровождение вашего
                проекта, обеспечивая его успешное и 
                впечатляющее завершение. 
            </p>
            <div className={cl.footer}>
                <div className={cl.footer__left}>
                <div className={cl.footer__left__1}>
                <div className={cl.circle} dangerouslySetInnerHTML={{__html : shereSvg}}></div>
                    <img src="image/services/footer/g1.jpg" alt="Фонтан" />
                </div>
                <div className={cl.footer__left__2}>
                    <img src="image/services/footer/v1.jpg" alt="Красивый фонтан" />
                    <img src="image/services/footer/v2.jpg" alt="фонтан" />
                </div>
                </div>
                <div className={cl.footer__right}>
                    <Footer />
                </div>
            </div>
      </div>
    )
  
}

export default Visual

