import React from 'react';
import cl from './ModalWindow.module.css'
import { IoMdCloseCircleOutline } from "react-icons/io";
import CustomForm from '../customForm/CustomForm'
import { Transition } from 'react-transition-group';

export const ModalWindow =({isOpen, onClose}) => {
  return (
    <>
    <Transition in={isOpen} timeout={350} unmountOnExit={true} >
    { (state) => (
    <div className={`${cl.modal} cl.modal__${state}`}>
        <div className={cl.modal__wrapper}>
            <div classModal={cl.modal__content}>
                <button className={cl.modal__close__btn}
                  onClick={() => onClose()}>
                    <IoMdCloseCircleOutline />
                </button>
                <CustomForm />
            </div>
        </div>
    </div>
    )}
    </Transition>
    </>
  )
}
