import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const Info = () => {

    return (
      <div>
          <Helmet>
            <title>Политика обработки персональных данных | АкваТехПласт | Волгоград</title>
          </Helmet>
        <div className="container">
          <div className="row">
            <div className="col">
              <Link to='/'>В начало</Link>
              <div>
                <h1>Политика обработки персональных данных</h1>
                <h3>Общие положения</h3>
                <ul>
                  <li>1.1. Настоящая политика обработки персональных данных 
                    разработана в соответствии с законодательством 
                    Российской Федерации, включая Федеральный 
                    закон от 27 июля 2006 года № 152-ФЗ "О персональных данных".</li>
                  <li>1.2. Владелец персональных данных: ООО "АкваТехПласт".</li>
                  <li>1.3. Цель обработки персональных данных: осуществление деятельности, 
                    предусмотренной уставом или договором, в 
                    том числе оказание услуг, предоставление 
                    информации и содействие в решении возможных вопросов.</li>
                  <li>1.4. Объекты персональных данных: физические лица, предоставившие свои персональные данные владельцу данных.</li>
                </ul>
                <h3>Сбор и обработка персональных данных</h3>
                <ul>
                  <li>2.1. Персональные данные могут включать, но не 
                    ограничиваться, следующей информацией: ФИО, 
                    контактная информация, адреса электронной почты, 
                    иные сведения, предоставленные субъектами данных.</li>
                  <li>2.2. Сбор и обработка персональных данных осуществляются на законной и 
                    справедливой основе с согласия субъектов данных.</li>
                  <li>2.3. Срок обработки персональных данных считается бессрочным,
                     пока не будет получено письменное заявление субъекта данных о прекращении их обработки.</li>
                </ul>
                <h3>Средства защиты персональных данных</h3>
                <ul>
                  <li>3.1. Для обеспечения безопасности персональных данных 
                    применяются необходимые правовые, организационные и технические меры.</li>
                  <li>3.2. Владелец персональных данных обязуется предотвращать 
                    несанкционированный доступ к персональным данным и предпринимать 
                    меры по их защите от утраты, изменения, разглашения или уничтожения.</li>
                </ul>
                <h3>Права субъектов персональных данных</h3>
                <ul>
                  <li>4.1. Субъекты персональных данных имеют 
                    право на получение информации о своих персональных данных, 
                    а также на их редактирование, обновление или уничтожение 
                    в случае утраты актуальности.</li>
                  <li>4.2. Субъекты данных могут в любой момент отозвать 
                    свое согласие на обработку персональных данных, 
                    направив письменное уведомление владельцу данных.</li>
                </ul>
                <h3>Ответственность</h3>
                <ul>
                  <li>5.1. Владелец персональных данных несет ответственность за 
                    соблюдение условий настоящей политики и законодательства Российской Федерации.</li>
                  <li>5.2. В случае возникновения вопросов, 
                    связанных с обработкой персональных данных, субъекты 
                    данных могут обращаться по контактной информации, 
                    указанной в конце настоящей политики.</li>
                </ul>
                <h4>Настоящая политика вступает в силу с момента ее публикации и 
                  действует бессрочно, подлежа изменению в порядке, 
                  установленном законодательством Российской Федерации.</h4>
                  <h5>Дата публикации 25.01.2024г.</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  
}

export default Info