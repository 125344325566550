import React from 'react'
import Header from '../../modules/header/Header'
import { Link } from 'react-router-dom';
import cl from './TypeFountain.module.css'
import SocialIcon from '../../UI/socialIcon/SocialIcon'
import AnimateFade from '../../UI/animateFade/AnimateFade'
import { Helmet } from 'react-helmet';

const TypeFountain = () => {


    return (
        <div className={cl.type}>
        <Helmet>
            <title>Типы фонтанов | АкваТехПласт | Волгоград</title>
            <meta name="keywords" content="Фонтаны, Каскадные фонтаны, Гейзерные фонтаны, Плавающие фонтаны, Проектирование фонтанов, Строительство фонтанов, Обслуживание фонтанов, Насосы для фонтанов, Технологии управления водой, Экологически устойчивые фонтаны" data-react-helmet="true" />
            <meta name="description" content="Разнообразие фонтанов: каскадные, гейзерные, плавающие. Проектирование, строительство, обслуживание. Эффективные системы насосов, технологии управления." />
        </Helmet>
            <AnimateFade />
            <div className={cl.type__right}>
                
                <div className={cl.type__right__block}>
                    <Link to='pedestrian'>
                        <div className={cl.flipper}>
                            <div className={`${cl.front} ${cl.bg1}`}>
                                <div className={cl.box}>
                                    <h2>Пешеходный фонтан</h2>
                                    <p>Эстетика и функциональность в урбанистическом дизайне</p>
                                </div>
                            </div>
                            <div className={cl.back}>
                                <p>Эстетика и функциональность в урбанистическом дизайне</p>
                                <h3>Подробнее...</h3>
                            </div>
                        </div>
                    </Link>
                </div>             
                <div className={cl.type__right__block}>
                    <Link to='sculptural'>
                        <div className={cl.flipper}>
                            <div className={`${cl.front} ${cl.bg2}`}>
                                <div className={cl.box}>
                                    <h2>Скульптурный фонтан</h2>
                                    <p>Уникальные водные скульптуры и детализированный дизайн</p>
                                </div>
                            </div>
                            <div className={cl.back}>
                                <p>Уникальные водные скульптуры и детализированный дизайн</p>
                                <h3>Подробнее...</h3>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className={cl.type__right__block}>
                    <Link to='cascade'>
                        <div className={cl.flipper}>
                            <div className={`${cl.front} ${cl.bg3}`}>
                                <div className={cl.box}>
                                    <h2>Каскадный фонтан</h2>
                                    <p>Эффектные ступени и водопады, создающие каскадный эффект</p>
                                </div>
                            </div>
                            <div className={cl.back}>
                                <p>Эффектные ступени и водопады, создающие каскадный эффект</p>
                                <h3>Подробнее...</h3>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className={cl.type__right__block}>
                    <Link to ='interactive'>
                    <div className={cl.flipper}>
                        <div className={`${cl.front} ${cl.bg4}`}>
                            <div className={cl.box}>
                                <h2>Интерактивный фонтан</h2>
                                <p>Взаимодействие освещением и цветовыми эффектами с посетителями</p>
                            </div>
                        </div>
                        <div className={cl.back}>
                            <p>Взаимодействие освещением и цветовыми эффектами с посетителями</p>
                            <h3>Подробнее...</h3>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className={cl.type__right__block}>
                        <div className={`${cl.front} ${cl.bg5}`}>
                            <div className={cl.box}>
                            </div>
                        </div>
                </div>
                <div className={cl.type__right__block}>
                    <SocialIcon />
                </div>
            </div>
            <div className={cl.type__left}>
                <Header />
                <div className={cl.type__title}>
                    <h1>Типы фонтанов</h1>
                </div>
            </div>
        </div>
    )
  
}

export default TypeFountain

