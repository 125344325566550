import React, { Component } from 'react'
import { GiWaterFountain } from "react-icons/gi";
import { CiCircleCheck } from "react-icons/ci";
import cl from '../style/Style.module.css'
import Footer from '../../../modules/footer/Footer';

const Сonsulting = () => {

    const shereSvg = `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.dev/svgjs" viewBox="0 0 800 800"><defs><radialGradient id="sssurface-grad-dark" r="75%" cx="20%" cy="20%">
    <stop offset="0%" stop-color="hsla(36, 98%, 51%, 0.36)" stop-opacity="0"></stop>
    <stop offset="100%" stop-color="#c46c005c" stop-opacity="0.14"></stop>
  </radialGradient><radialGradient id="sssurface-grad-light" r="53%" cx="33%" cy="30%">
  <stop offset="0%" stop-color="#ffff7d5c" stop-opacity="0.75"></stop>
  <stop offset="100%" stop-color="hsla(36, 98%, 51%, 0.36)" stop-opacity="0"></stop>
</radialGradient></defs><g><circle r="350" cx="400" cy="400" fill="hsla(36, 98%, 51%, 0.36)"></circle><circle r="350" cx="400" cy="400" fill="url(#sssurface-grad-dark)"></circle><circle r="350" cx="400" cy="400" fill="url(#sssurface-grad-light)"></circle></g></svg>`;


    return (
      <div className={cl.block}>
        <h2>Профессиональная Проверка и <br />Консультации по Проектам Фонтанов</h2>
        <h3>Вы сталкиваетесь с важными решениями 
            в области создания фонтанов, и мы 
            готовы предоставить вам высококачественные 
            консультации и экспертные услуги. Наша команда 
            специалистов предлагает полный анализ ваших коммерческих 
            предложений, проверку купленных проектов и консультации по 
            устройству фонтана для самостоятельного строительства.</h3>
            <div className={cl.logotype}>
                <div className={cl.logotype__line}></div>
                <div className={cl.logotype__icon}>
                    <GiWaterFountain />
                </div>
                <div className={cl.logotype__line}></div>
            </div>
            <h4>Почему важна проверка проекта:</h4>
            <p>При создании фонтанов, неверные 
                технические решения могут привести к 
                серьезным последствиям. Ошибки в проектировании 
                могут повлечь за собой неэффективное 
                использование ресурсов, проблемы с безопасностью и 
                даже долгосрочные технические сбои. Неправильный 
                монтаж может привести к утечкам, недостаточной 
                циркуляции воды или даже повреждению инфраструктуры.</p>
            <div className={cl.block__block}>
                <div className={cl.block__block__text}>
                    <h4>Почему следует доверить проверку нам:</h4>
                    <ul>
                        <span>Техническая Глубина</span>
                        <CiCircleCheck />
                        <li>Наши эксперты обладают глубокими 
                            техническими знаниями в области 
                            создания фонтанов. Мы проводим 
                            тщательный анализ проектов, 
                            уделяя внимание каждой детали</li>
                        <span>Последствия Ошибок</span>
                        <CiCircleCheck />
                        <li>В нашем заключении мы подчеркнем 
                            потенциальные последствия ошибок 
                            и неправильного монтажа, 
                            обеспечивая вас информацией 
                            для принятия обоснованных решений.</li>
                        <span>Профессиональная Этика</span>
                        <CiCircleCheck />
                        <li>Наша компания придерживается 
                            высоких профессиональных стандартов. 
                            Мы являемся третьей, независимой и 
                            незаинтересованной стороной, 
                            гарантируя объективное заключение.</li>
                    </ul>
                </div>
                <div className={cl.block__block__svg}>
                    <div dangerouslySetInnerHTML={{__html : shereSvg}}></div>
                </div>
            </div>
            <div className={cl.logotype}>
                <div className={cl.logotype__line}></div>
                <div className={cl.logotype__icon}>
                    <GiWaterFountain />
                </div>
                <div className={cl.logotype__line}></div>
            </div>
            <p>Создание фонтана - это сложный процесс, 
                требующий специализированных знаний. 
                Несоблюдение технических стандартов и 
                профессиональных подходов может привести к 
                дополнительным расходам и проблемам. 
                Мы настоятельно рекомендуем обращаться к 
                профессионалам для строительства фонтанов, 
                и мы готовы поделиться нашим опытом и 
                консультациями, чтобы ваш проект был 
                успешным и долговечным.</p>
                <p>Поверьте проверку вашего проекта 
                профессионалам, чтобы избежать 
                неприятных сюрпризов в будущем. 
                Наша команда стоит к вашим услугам, 
                гарантируя высокий уровень профессионализма 
                и ответственности.
            </p>
            <div className={cl.footer}>
                    <div className={cl.footer__left}>
                    <div className={cl.circle} dangerouslySetInnerHTML={{__html : shereSvg}}></div>
                        <div className={cl.footer__left__1}>
                            <img src="image/services/footer/g3.jpg" alt="Фонтан" />
                        </div>
                        <div className={cl.footer__left__2}>
                            <img src="image/services/footer/v5.jpg" alt="Красивый фонтан" />
                            <img src="image/services/footer/v6.jpg" alt="фонтан" />
                        </div>
                    </div>
                    <div className={cl.footer__right}>
                        <Footer />
                    </div>
                </div>
      </div>
    )
  
}

export default Сonsulting

