import React, { Component } from 'react'
import cl from './NoFound.module.css'


const NoFound = () => {

    return (
      <div className={cl.body}>
        <div className={cl.body__bg}>
            <h1>АкваТехПласт</h1>
            <h2>Строительство фонтанов</h2>
            <div className={cl.eyes}>
                <div className={cl.eyes__eye}>
                    <div className={cl.eyes__eye__pupil}></div>
                </div>
                <div className={cl.eyes__eye}>
                    <div className={cl.eyes__eye__pupil}></div>
                </div>
            </div>
            <h3><span>404</span></h3>
            <h3>Страница не найдена</h3>
            <a href="https://fontan34.ru">Вернуться на сайт</a>
        </div>
      </div>
    )

}

export default NoFound