import React, {useState, useEffect} from 'react';
import { Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './variables/baseStyle/BaseStyle.module.css';
import Main from './components/pages/main/Main';
import TypeFountain from './components/pages/type/TypeFountain';
import Pedestrian from './components/pages/type/pedestrian/Padestrian'
import Sculptural from './components/pages/type/sculptural/Sculptural';
import Cascade from './components/pages/type/cascade/Cascade';
import Interactive from './components/pages/type/interactive/Interactive';
import Contact from './components/pages/contact/Contact';
import LoadingPage from './components/UI/loadingPage/LoadingPage'
import Info from './components/pages/license/info/Info';
import Services from './components/pages/services/Services';
import ContactWiget from './components/UI/contactWiget/ContactWiget';
import ScrollToTop from './components/UI/scrollToTop/ScrollToTop';
import Gallery from './components/pages/gallery/Gallery';
import NoFound from './components/pages/noFound/NoFound';
import License from './components/pages/license/license/License';
import Cookie from './components/pages/license/cookie/Cookie';
import CookieNotification from './components/UI/cookieNotification/CookieNotification';





const App = () => {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2500);

    return () => clearTimeout(timeout);
  }, []);

  const [isOpenModal, setIsOpenModal] = useState(false)

  return (
      <>{loading ? (
            <LoadingPage />
            ) : (
             <> 
            <Routes>
              <Route path="/" element={<Main />} />
              <Route path='/services' element={<Services />} />
              <Route path='/gallery' element={<Gallery />} />
              <Route path="/type" element={<TypeFountain />} />
              <Route path='/type/pedestrian'  element={<Pedestrian />} />
              <Route path='/type/sculptural'  element={<Sculptural />} />
              <Route path='/type/cascade' element={<Cascade />} />
              <Route path='/type/interactive' element={<Interactive />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/info' element={<Info />} />
              <Route path='/license' element={<License />} />
              <Route path='/cookie' element={<Cookie />} />
              <Route path='*' element={<NoFound />} />
            </Routes>
            <ContactWiget />
            <ScrollToTop />
            <CookieNotification />
            </>
            )}
        </>
    );
};

export default App;


