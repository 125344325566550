import React, { useState } from 'react'
import { SwiperSlide } from 'swiper/react';
import CustomSwiper from '../../UI/customSwiper/CustomSwiper'
import cl from './Main.module.css'
import { PiTelegramLogoThin, PiPhoneThin, PiAtThin, PiWhatsappLogoThin  } from "react-icons/pi";
import Menu from '../../modules/menu/Menu';
import PhoneMenu from '../../modules/phoneMenu/PhoneMenu';
import { ModalWindow } from '../../UI/modalWindow/ModalWindow';
import { Helmet } from 'react-helmet';





const Main = () => {

    const currentYear = new Date().getFullYear();


    const swiperParams = {
        spaceBetween: 30,
        centeredSlides: true,
        autoplay: {
          delay: 6500,
          disableOnInteraction: false,
        },
        pagination: {
          clickable: true,
        },
        
      };

    const nextSwiperParams = {
        spaceBetween: 30,
        centeredSlides: true,
        autoplay: {
          delay: 6500,
          disableOnInteraction: false,
        },
        cubeEffect: {
            shadow: false,
            slideShadows: false,
            shadowOffset: 20,
            shadowScale: 0.94,
          },
        pagination: {
          clickable: true,
        },
        effect : 'cube'
      };

    const [isOpenModal, setIsOpenModal] = useState(false)
    
    return (
      <div className={cl.main}>
        <Helmet>
            <title>АкваТехПласт | Строительство фонтанов | Волгоград</title>
            <meta name="keywords" content="Фонтаны, строительство фонтанов в Волгограде, АкваТехПласт Волгоград, Пешеходные фонтаны, строим фонтаны, большие фонтаны, фонтан для участка" data-react-helmet="true" />
            <meta name="description" content="Специализируемся на проектировании, строительстве и обслуживании фонтанов. Уникальные дизайны, комплексные земельные и монтажные работы, техническое обслуживание и экологически устойчивые решения." />
        </Helmet>
        <section className={cl.main__body}>
            <div className={cl.main__body__left}>
                <PhoneMenu />
                <div className={cl.main__body__left__head}>
                    <Menu />
                    <a href="https://fontan34.ru">
                        <h2>Строительство фонтанов</h2>
                        <h1>АкваТехПласт</h1>
                        
                    </a>
                </div>
                <div className={cl.animate}></div>
            </div>
            <div className={cl.main__body__right}>
                <div className={cl.main__body__right__top}>
                    <div className={cl.main__body__right__top__left}>
                        <div className={cl.main__body__right__top__left__made}>
                            <p>Российский</p>
                            <p>производитель</p>
                        </div>
                        <div className={cl.main__body__right__top__left__text}>
                        <div className={cl.main__body__right__top__left__text__years}>
                            <p>2013</p>
                            <p>&#160;&#8212;&#160;</p>
                            <p>{currentYear}</p>
                        </div>
                            <CustomSwiper {...swiperParams}>
                                <SwiperSlide>
                                    <div className={cl.main__body__right__top__left__text__body}>
                                        <h3>Эксперты в Строительстве Фонтанов</h3>
                                        <p>Мы специализируемся на инновационных подходах, технической экспертизе и индивидуальных решениях.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className={cl.main__body__right__top__left__text__body}>
                                        <h3>Инженеры Фонтанного Дизайна</h3>
                                        <p>Строим высокотехнологичные фонтаны, индивидуально адаптируемые под потребности клиента.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className={cl.main__body__right__top__left__text__body}>
                                        <h3>Эстетика и Функциональность в каждом Фонтане</h3>
                                        <p>Решения сочетающие в себе эстетику и технологии для превращения общественных мест в уникальные.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className={cl.main__body__right__top__left__text__body}>
                                        <h3>Качество и Надежность в каждом Проекте</h3>
                                        <p>Мы заботимся о долгосрочной функциональности каждого созданного нами фонтана.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className={cl.main__body__right__top__left__text__body}>
                                        <h3>Персональный Подход и Инженерная Точность</h3>
                                        <p>Подход обеспечивающий гармоничное сочетание креативности и профессионализма в каждом проекте.</p>
                                    </div>
                                </SwiperSlide>
                            </CustomSwiper>
                            <div className={cl.main__send}>
                                <button className={cl.modalbtn} onClick={() => setIsOpenModal(true)}>Подробнее...</button>
                            </div>
                        </div>
                        <div className={cl.animate}></div>
                    </div>
                     <div className={cl.main__body__right__top__right}>
                        <CustomSwiper {...nextSwiperParams}>
                            <SwiperSlide>
                               <div className={cl.one}></div> 
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={cl.two}></div>
                            </SwiperSlide>   
                            <SwiperSlide>
                                <div className={cl.therre}></div>
                            </SwiperSlide> 
                            <SwiperSlide>
                                <div className={cl.four}></div>
                            </SwiperSlide>  
                            <SwiperSlide>
                                <div className={cl.five}></div>
                            </SwiperSlide>   
                                 
                        </CustomSwiper>
                        <div className={cl.animate}></div>
                    </div>
                </div>
                <div className={cl.main__body__right__bottom}>
                    <div className={cl.main__body__right__bottom__left}>
                    <div className={cl.animate}></div>
                    </div>
                    <div className={cl.main__body__right__bottom__center}>
                        <div className={cl.main__body__right__bottom__center__top}>
                            <h4>Связаться с нами</h4>
                            <div className={cl.buster}>
                                <div className={cl.main__body__right__bottom__center__top__icons}>
                                <a href="tel:+79023815963">
                                    <PiPhoneThin />
                                </a>
                            </div>
                            <div className={cl.main__body__right__bottom__center__top__icons}>
                                <a href="https://wa.me/79023815963" target='blank'>
                                    <PiWhatsappLogoThin />
                                </a>
                            </div>
                            <div className={cl.main__body__right__bottom__center__top__icons}>
                                <a href="mailto:info@fontan34.ru">
                                    <PiAtThin/>
                                </a>
                            </div>
                            <div className={cl.main__body__right__bottom__center__top__icons}>
                                <a href="https://t.me/fontan_atp"  target='blank'>
                                    <PiTelegramLogoThin/>
                                </a>
                            </div>
                            </div>
                            <div className={cl.buster__contact}>
                                <h6>Наш адрес:</h6>
                                <p>г. Волгоград</p>
                                <p>ул. 25 лет Октября, 1, стр. 105 (этаж 2)</p>
                            </div>
                            <div className={cl.buster__line}></div>
                            <div className={cl.buster__inn}>
                                <p>ООО «АкваТехПласт»</p>
                                <p>ИНН 3460006945, КПП 346001001</p>
                                <p>ОГРН 1133443009842, ОКПО 13725807</p>
                                <a href="https://build-root.ru" target='blank'>Разработано "b_root" &copy; {currentYear}</a>
                            </div>
                        </div>
                        <div className={cl.animate}></div>
                    </div>
                    <div className={cl.main__body__right__bottom__right}>
                    <div className={cl.animate}></div>
                    </div>
                </div>
            </div>
        </section>
        <ModalWindow 
            isOpen={isOpenModal}
            onClose={() => setIsOpenModal(false)}
         />
      </div>
    )
}

export default Main 