import React, { useRef, useState } from 'react'
import { TbFountain } from "react-icons/tb";
import { Link } from 'react-router-dom';
import cl from './Menu.module.css'


const Menu = () => {
    const [isBlockVisible, setIsBlockVisible] = useState(false)
    

    
    const handleClick = () => { 
      setIsBlockVisible(!isBlockVisible);
      setIsMenuOpen(!isMenuOpen);
    }

    
      const [isMenuOpen, setIsMenuOpen] = useState(false);
    


   

    return (
      <nav className={cl.nav}>
        <div onClick={handleClick} className={`${cl.burger} ${isMenuOpen ? cl.cross : ''}`}>
            <div className={cl.burger__toggle}></div>
            <div className={cl.burger__toggle}></div>
            <div className={cl.burger__toggle}></div>
        </div>
        <div  style={{ display: isBlockVisible ? 'block' : 'none'}} className={cl.menu}>
          <div className={cl.menu__list}>
            <ul>
              <li><Link to='/services'>
                <div className={cl.block}>
                  <div className={cl.block__bg}>
                    <p>Услуги и цены</p>
                    <TbFountain/>
                  </div>
                </div>
                </Link></li>
              <li><Link to='/type'>
                <div className={cl.block}>
                  <div className={cl.block__bg}>
                    <p>Типы фонтанов</p>
                    <TbFountain/>
                  </div>
                </div>
              </Link></li>
              <li><Link to='/gallery'>
                <div className={cl.block}>
                  <div className={cl.block__bg}>
                    <p>Галерея</p>
                    <TbFountain/>
                  </div>
                </div>
              </Link></li>
              <li><Link to='/contact'>
                <div className={cl.block}>
                  <div className={cl.block__bg}>
                    <p>Контакты</p>
                    <TbFountain/>
                  </div>
                </div>
              </Link></li>
            </ul>
            <a href='https://fontan34.ru' className={cl.logo}></a>
          </div>
        </div>
      </nav>
    )
}

export default Menu