import React, { useState} from 'react'
import Header from '../../../modules/header/Header'
import cl from '../style/Style.module.css'
import { CiCircleCheck } from "react-icons/ci";
import { GiWaterFountain } from "react-icons/gi";
import Footer from '../../../modules/footer/Footer';
import AnimateFade from '../../../UI/animateFade/AnimateFade'
import { ModalWindow } from '../../../UI/modalWindow/ModalWindow';
import { Helmet } from 'react-helmet';


const Padestrian = () => {


    const [isOpenModal, setIsOpenModal] = useState(false)

    return (
        <div className={cl.main}>
        <Helmet>
            <title>Пешеходные фонтаны | АкваТехПласт | Волгоград</title>
            <meta name="keywords" content="Пешеходные фонтаны, безопасность, гидравлические системы, минимальные брызги, техническое обслуживание, инженерные решения, устойчивость, дизайн, водостойкость, архитектурная интеграция, эффективность, гармония."/>
            <meta name="description" content="Исследуйте пешеходные фонтаны: проектирование с учетом безопасности, гидравлические системы для минимального брызг, техническое обслуживание и инженерные решения для гармонии с окружающей средой." />
        </Helmet>
            <div className={cl.main__left}>
                <AnimateFade />
                <div className={cl.main__left__title}>
                    <h1>Пешеходные фонтаны</h1>
                </div>
            </div>
            <div className={cl.main__right}>
                <AnimateFade />
                <Header />
                <div className={cl.main__right__top}>
                <div className={cl.main__right__top__left}>
                    <h2>Пешеходные фонтаны стали неотъемлемой частью городского пейзажа, 
                    объединяя в себе красоту и функциональность. Их разнообразие 
                    и возможности применения делают их важным 
                    элементом современной городской инфраструктуры.</h2>
                    <h3>Технологии, лежащие в основе современных пешеходных фонтанов, 
                        создают удивительный симбиоз воды, света и звука. 
                        Эти технически сложные системы не только служат украшением 
                        городского пространства, но и предоставляют возможности для 
                        креативного проектирования и архитектурных инноваций, 
                        совмещая в себе инженерные решения и художественные идеи.</h3>
                    <button className={cl.modalbtn} onClick={() => setIsOpenModal(true)}>Оставить заявку</button>
                    
                </div>
                <div className={cl.main__right__top__right}>
                    <img src="/image/type/dry/001.jpg" alt="Сухой фонтан ночью" />
                </div>
                </div>
                <div className={cl.main__right__bottom__top}>
                    <div className={cl.main__right__bottom__top__line}></div>
                    <div className={cl.main__right__bottom__top__icon}>
                        <GiWaterFountain />
                    </div>
                    <div className={cl.main__right__bottom__top__line}></div>
                </div>
                <div className={cl.main__right__center}>
                    <div className={cl.main__right__center__left}>
                        <img src="/image/type/dry/005.jpg" alt="Сухой фонтан ночью" />
                    </div>
                    <div className={cl.main__right__center__center}>
                        
                        <ul>
                            <li>
                                <CiCircleCheck />
                                <span>Площадка для различных мероприятий:</span>
                                <p> Концерты, фестивали, свадьбы.</p>
                            </li>
                            <li>
                                <CiCircleCheck />
                                <span>Эстетика и декоративность:</span>
                                <p> Улучшение облика городского пространства.</p>
                            </li>
                            <li>
                                <CiCircleCheck />
                                <span>Создание уникальных визуальных эффектов:</span>
                                <p> Изменение форм и высоты струй, использование подсветки.</p>
                            </li>
                            <li>
                                <CiCircleCheck />
                                <span>Улучшение городской среды:</span>
                                <p>  Создание микроклимата, уменьшение пыли и тепла.</p>
                            </li>
                        </ul>
                    </div>
                    <div className={cl.main__right__center__right}>
                        <img src="/image/type/dry/003.jpg" alt="Сухой фонтан ночью" />
                    </div>
                </div>
                <div className={cl.main__right__bottom}>
                    <div className={cl.main__right__bottom__top}>
                        <div className={cl.main__right__bottom__top__line}></div>
                        <div className={cl.main__right__bottom__top__icon}>
                            <GiWaterFountain />
                        </div>
                        <div className={cl.main__right__bottom__top__line}></div>
                    </div>
                    <div className={cl.main__right__bottom__center}>
                        <h5>Пешеходные фонтаны обладают широким 
                            спектром возможностей применения. Они 
                            становятся центральным элементом 
                            городского ландшафта, привлекая 
                            внимание как местные жителей, так и 
                            туристов. Фонтаны часто становятся
                            площадками для культурных событий, 
                            концертов и праздничных мероприятий.</h5>
                        <p>В коммерческих и деловых районах пешеходные 
                            фонтаны могут быть использованы для создания 
                            приятной атмосферы, повышения 
                            привлекательности территории и 
                            стимулирования торговли.</p>  
                        <p>Современные фонтаны также включают в себя 
                            системы фильтрации и очистки воды, что 
                            позволяет поддерживать высокие стандарты 
                            гигиеничности и обеспечивать безопасность 
                            для пешеходов.</p>   
                        <p>Современные пешеходные фонтаны используют 
                            технологии, позволяющие создавать 
                            разнообразные и красочные водные эффекты. 
                            Для контроля высоты, направления и формы 
                            струй применяются насосы переменной мощности 
                            и форсунки с регулируемым диаметром. 
                            Это позволяет создавать впечатляющие 
                            водные образы, включая геометрические 
                            формы, водные завесы и даже танцующие струи.</p> 
                        <p>Интегрированные системы подсветки добавляют 
                            водным фонтанам визуальную привлекательность. 
                            LED-подсветка позволяет создавать тысячи 
                            различных цветовых комбинаций, динамично 
                            меняющихся во времени. Такие системы подсветки 
                            также могут быть синхронизированы с музыкой 
                            или другими аудиовизуальными эффектами, 
                            создавая впечатляющий мультисенсорный опыт.</p>  
                        <button className={cl.modalbtn} onClick={() => setIsOpenModal(true)}>Оставить заявку</button>    
                    </div>
                    <div className={cl.main__right__bottom__bottom}>
                        <div className={cl.main__right__bottom__bottom__img}>
                            <div className={cl.img}>
                                <div className={cl.img__left}>
                                    <img src="/image/type/dry/003.jpg" alt="Пешеходные фонтаны" />
                                </div>
                                <div className={cl.img__right}>
                                    <img src="/image/type/dry/008.jpg" alt="Пешеходные фонтаны" />
                                    <img src="/image/type/dry/006.jpg" alt="Пешеходные фонтаны" />
                                </div>
                            </div>
                            <div className={cl.img}>
                                <div className={cl.img__left}>
                                    <img src="/image/type/dry/005.jpg" alt="Пешеходные фонтаны" />
                                </div>
                                <div className={cl.img__right}>
                                    <img src="/image/type/dry/004.jpg" alt="Пешеходные фонтаны" />
                                    <img src="/image/type/dry/002.jpg" alt="Пешеходные фонтаны" />
                                </div>
                            </div>
                        </div>
                            
                        <div className={cl.main__right__bottom__bottom__footer}>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
            <ModalWindow 
            isOpen={isOpenModal}
            onClose={() => setIsOpenModal(false)}
         />
        </div>
    )
}

export default Padestrian