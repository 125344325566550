import React from 'react';
import { useSpring, animated, config } from 'react-spring';
import cl from './LoadingPage.module.css'

const LoadingPage = () => {
    const props = useSpring({
      opacity: 1,
      from: { opacity: 1, },
      
      config: config.molasses,
      reset: true,
    });
  
    return (
      <animated.div className={cl.body} style={props}>
          <div className={cl.text}>
            <h3>«АкваТехПласт»</h3>
            <h4>Строительство фонтанов</h4>
          </div>
      </animated.div>
    );
  };
  
  export default LoadingPage 