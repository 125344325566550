import React, { Component } from 'react'
import { GiWaterFountain } from "react-icons/gi";
import { CiCircleCheck } from "react-icons/ci";
import cl from '../style/Style.module.css'
import Footer from '../../../modules/footer/Footer';

const Desing = () => {

    const shereSvg = `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.dev/svgjs" viewBox="0 0 800 800"><defs><radialGradient id="sssurface-grad-dark" r="75%" cx="20%" cy="20%">
    <stop offset="0%" stop-color="hsla(36, 98%, 51%, 0.36)" stop-opacity="0"></stop>
    <stop offset="100%" stop-color="#c46c005c" stop-opacity="0.14"></stop>
    </radialGradient><radialGradient id="sssurface-grad-light" r="53%" cx="33%" cy="30%">
    <stop offset="0%" stop-color="#ffff7d5c" stop-opacity="0.75"></stop>
    <stop offset="100%" stop-color="hsla(36, 98%, 51%, 0.36)" stop-opacity="0"></stop>
    </radialGradient></defs><g><circle r="350" cx="400" cy="400" fill="hsla(36, 98%, 51%, 0.36)"></circle><circle r="350" cx="400" cy="400" fill="url(#sssurface-grad-dark)"></circle><circle r="350" cx="400" cy="400" fill="url(#sssurface-grad-light)"></circle></g></svg>`;


    return (
      <div className={cl.block}>
        <h2>Профессиональное Проектирование Фонтанов</h2>
        <h3>Проектирование фонтанов – 
            это сложный технический процесс, 
            который требует высокой квалификации и 
            глубоких знаний. На каждом этапе этого 
            ответственного мероприятия необходимо 
            учесть множество аспектов, начиная от 
            структурных инженерных решений и 
            заканчивая системами водоподготовки 
            и автоматики. Наш профессиональный 
            подход к проектированию фонтанов 
            обеспечивает не только визуальное 
            восхищение, но и строгую техническую 
            выверенность каждого элемента
        </h3>
            <div className={cl.logotype}>
                <div className={cl.logotype__line}></div>
                <div className={cl.logotype__icon}>
                    <GiWaterFountain />
                </div>
                <div className={cl.logotype__line}></div>
            </div>
            <h4>От Идеи до Реальности:</h4>
            <p>Важным аспектом нашего профессионализма 
                является глубокое понимание и интеграция 
                всех технических элементов. Наши 
                проектировщики обладают не только 
                знанием в области железобетонных 
                конструкций, электрики и автоматики, 
                но и широким спектром компетенций, 
                включая водоподготовку, системы 
                фильтрации и водоснабжение. Мы 
                гарантируем соответствие каждого 
                этапа проекта высоким техническим 
                стандартам.
            </p>
            <div className={cl.block__block}>
                <div className={cl.block__block__text}>
                    <h4>Почему следует довериться нам:</h4>
                    <ul>
                        <span>Интеграция Технологий</span>
                        <CiCircleCheck />
                        <li>Наши проектировщики обладают 
                            глубоким пониманием современных 
                            технологий в области фонтанов.
                        </li>
                        <span>Соблюдение Норм и Требований</span>
                        <CiCircleCheck />
                        <li>Мы придерживаемся всех необходимых 
                            ГОСТов, СНиПов и ПУЭ на каждом 
                            этапе проектирования
                        </li>
                        <span>Техническая Гарантия</span>
                        <CiCircleCheck />
                        <li>Мы предоставляем подробные 
                            технические спецификации, 
                            обеспечивая прозрачность и 
                            понимание всех технических 
                            аспектов проекта.
                        </li>
                    </ul>
                </div>
                <div className={cl.block__block__svg}>
                    <div dangerouslySetInnerHTML={{__html : shereSvg}}></div>
                </div>
            </div>
            <div className={cl.logotype}>
                <div className={cl.logotype__line}></div>
                <div className={cl.logotype__icon}>
                    <GiWaterFountain />
                </div>
                <div className={cl.logotype__line}></div>
            </div>
            <p>Профессионализм в проектировании фонтанов – 
                это не просто обещание красоты, но и техническая 
                уверенность в каждой детали. Мы приглашаем вас
                выбрать нас для вашего проекта фонтана, где
                каждая инженерная деталь, каждая система и 
                технологическое решение будут отражением 
                нашего технического мастерства. Создадим 
                вместе фонтан, который не только впечатлит 
                визуально, но и будет функциональным шедевром 
                с высокой технической надежностью.
            </p>
            <div className={cl.footer}>
                    <div className={cl.footer__left}>
                    <div className={cl.circle} dangerouslySetInnerHTML={{__html : shereSvg}}></div>
                        <div className={cl.footer__left__1}>
                            <img src="image/services/footer/g2.jpg" alt="Фонтан" />
                        </div>
                        <div className={cl.footer__left__2}>
                            <img src="image/services/footer/v3.jpg" alt="Красивый фонтан" />
                            <img src="image/services/footer/v4.jpg" alt="фонтан" />
                        </div>
                    </div>
                    <div className={cl.footer__right}>
                        <Footer />
                    </div>
                </div>
      </div>
    )
  
}

export default Desing

