import React, { Component } from 'react'
import { GiWaterFountain } from "react-icons/gi";
import { CiCircleCheck } from "react-icons/ci";
import cl from '../style/Style.module.css'
import Footer from '../../../modules/footer/Footer';

const Build = () => {

    const shereSvg = `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.dev/svgjs" viewBox="0 0 800 800"><defs><radialGradient id="sssurface-grad-dark" r="75%" cx="20%" cy="20%">
    <stop offset="0%" stop-color="hsla(36, 98%, 51%, 0.36)" stop-opacity="0"></stop>
    <stop offset="100%" stop-color="#c46c005c" stop-opacity="0.14"></stop>
  </radialGradient><radialGradient id="sssurface-grad-light" r="53%" cx="33%" cy="30%">
  <stop offset="0%" stop-color="#ffff7d5c" stop-opacity="0.75"></stop>
  <stop offset="100%" stop-color="hsla(36, 98%, 51%, 0.36)" stop-opacity="0"></stop>
</radialGradient></defs><g><circle r="350" cx="400" cy="400" fill="hsla(36, 98%, 51%, 0.36)"></circle><circle r="350" cx="400" cy="400" fill="url(#sssurface-grad-dark)"></circle><circle r="350" cx="400" cy="400" fill="url(#sssurface-grad-light)"></circle></g></svg>`;

    return (
      <div className={cl.block}>
        <h2>Строительство Фонтанов</h2>
        <h3>Строительство фонтанов представляет 
            собой комплексный и трудоемкий процесс, 
            требующий тщательного планирования и высокой 
            технической компетентности. Это воплощение 
            коллективных усилий инженеров, архитекторов, 
            дизайнеров, скульпторов и монтажников. От 
            выбора места до финишной отделки, каждый 
            этап требует точной координации и технической 
            осведомленности.
        </h3>
            <div className={cl.logotype}>
                <div className={cl.logotype__line}></div>
                <div className={cl.logotype__icon}>
                    <GiWaterFountain />
                </div>
                <div className={cl.logotype__line}></div>
            </div>
            <h4>От Идеи до Реальности:</h4>
            <p>На первом этапе, проектирование, 
                определяются не только эстетические 
                параметры, но и технические аспекты, 
                учитывая финансовые возможности заказчика 
                и технические условия строительства. 
                Гидротехническая часть, в свою очередь, 
                включает в себя расчет мощности насосов, 
                определение диаметра труб, гидростатический 
                анализ и подготовку сметы.
            </p>
            <p>Процесс строительства начинается с 
                подготовки фундамента, где важными 
                моментами являются размеры котлована и 
                прокладка коммуникаций. Возведение чаши, 
                требующее внимания к гидроизоляции и 
                бетонированию, подразумевает применение 
                высококачественного бетона. Финишная отделка, 
                в зависимости от предпочтений, включает в себя 
                натуральный камень, мозаику, керамическую 
                плитку или другие декоративные материалы
            </p>
            <div className={cl.block__block}>
                <div className={cl.block__block__text}>
                    <h4>Почему следует довериться нам:</h4>
                    <ul>
                        <span>Техническая Экспертиза</span>
                        <CiCircleCheck />
                        <li>Наши специалисты обладают глубокими 
                            техническими знаниями, обеспечивая 
                            высокий стандарт выполнения каждого 
                            этапа строительства.
                        </li>
                        <span>Оптимизированные Решения</span>
                        <CiCircleCheck />
                        <li>Мы предлагаем оптимизированные решения, 
                            учитывая технические и финансовые 
                            аспекты, что обеспечивает эффективное и 
                            экономичное строительство.
                        </li>
                        <span>Качество Материалов</span>
                        <CiCircleCheck />
                        <li>
                            Применение бетона высокого 
                            качества и разнообразие отделочных 
                            материалов гарантируют долговечность 
                            и эстетическую привлекательность 
                            фонтанов.
                        </li>
                    </ul>
                </div>
                <div className={cl.block__block__svg}>
                    <div dangerouslySetInnerHTML={{__html : shereSvg}}></div>
                </div>
            </div>
            <div className={cl.logotype}>
                <div className={cl.logotype__line}></div>
                <div className={cl.logotype__icon}>
                    <GiWaterFountain />
                </div>
                <div className={cl.logotype__line}></div>
            </div>
            <p>Строительство фонтанов требует не только 
                креативности, но и высокого уровня 
                технической компетентности. 
                Сотрудничество с нами обеспечивает 
                точное выполнение каждого этапа, а 
                наши технические эксперты гарантируют 
                воплощение ваших идей в функциональные 
                и эстетичные водные объекты. Выберите 
                нас для тщательно продуманного и 
                технически совершенного строительства 
                фонтанов.
            </p>
            <div className={cl.footer}>
                    <div className={cl.footer__left}>
                    <div className={cl.circle} dangerouslySetInnerHTML={{__html : shereSvg}}></div>
                    <div className={cl.footer__left__1}>
                            <img src="image/services/footer/g4.jpg" alt="Фонтан" />
                        </div>
                        <div className={cl.footer__left__2}>
                            <img src="image/services/footer/v7.jpg" alt="Красивый фонтан" />
                            <img src="image/services/footer/v8.jpg" alt="фонтан" />
                        </div>
                    </div>
                    <div className={cl.footer__right}>
                        <Footer />
                    </div>
                </div>
      </div>
    )
  
}

export default Build

