import React, { Component } from 'react'
import cl from './Header.module.css'
import Navigation from '../../UI/navigation/Navigation'
import Breadcrumbs from '../../UI/breadScrumbs/BreadScrumbs'

const Header = () => {

    return (
      <header className={cl.header} id="header">
          <div className={cl.header__left}>
            
            <Navigation />
            <Breadcrumbs />
          </div>
          <div clasName={cl.header__right}>
            <div>
              <a  className={cl.header__right__logo} href="https://fontan34.ru">
                <img className={cl.logo} src={process.env.PUBLIC_URL + '/image/logo/logo.png'} ></img>
                <h2>Строительство Фонтанов</h2>
              </a>
            </div>
          </div>
          
      </header>
    )
}

export default Header

