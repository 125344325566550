import React, { useState} from 'react'
import Header from '../../../modules/header/Header'
import cl from '../style/Style.module.css'
import { CiCircleCheck } from "react-icons/ci";
import { GiWaterFountain } from "react-icons/gi";
import Footer from '../../../modules/footer/Footer';
import AnimateFade from '../../../UI/animateFade/AnimateFade'
import { ModalWindow } from '../../../UI/modalWindow/ModalWindow';
import { Helmet } from 'react-helmet';

const Sculptural = () => {


    const [isOpenModal, setIsOpenModal] = useState(false)

    return (
        <div className={cl.main}>
        <Helmet>
            <title>Скульптурные фонтаны | АкваТехПласт | Волгоград</title>
            <meta name="keywords" content="Скульптурные фонтаны, Проектирование, Гидравлические расчеты, Насосные системы, Строительство, Водные композиции, Архитектурные фонтаны, Ландшафтный дизайн, Искусство воды, Современные фонтаны, Инженерия фонтанов, Эстетика воды" data-react-helmet="true" />
            <meta name="description" content="Разберитесь в скульптурных фонтанах: проектирование, точные гидравлические расчеты, эффективные насосные системы и строительство для уникальных водных композиций." />
        </Helmet>
            <div className={cl.main__left}>
                <AnimateFade />
                <div className={cl.main__left__title}>
                    <h1>Скульптурные фонтаны</h1>
                    
                </div>
            </div>
            <div className={cl.main__right}>
                <AnimateFade />
                <Header />
                <div className={cl.main__right__top}>
                <div className={cl.main__right__top__left}>
                    <h2>Скульптурные фонтаны придают окружающей среде 
                        изысканный и утонченный вид. Они становятся 
                        центральным элементом ландшафтного дизайна, 
                        придавая уникальный характер и стиль окружающему 
                        пространству.</h2>
                    <h3>Создание симбиоза искусства и инженерии, 
                        скульптурные фонтаны стали визитной карточкой 
                        современных городских пространств и частных усадеб. 
                        Эти уникальные сооружения объединяют в себе техническую 
                        изысканность, эстетическую привлекательность и функциональность, 
                        превращая обыденные места в источники вдохновения</h3>

                    <button className={cl.modalbtn} onClick={() => setIsOpenModal(true)}>Оставить заявку</button>
                </div>
                <div className={cl.main__right__top__right}>
                    <img src="/image/type/sculpture/003.jpg" alt="Сухой фонтан ночью" />
                </div>
                </div>
                <div className={cl.main__right__bottom__top}>
                    <div className={cl.main__right__bottom__top__line}></div>
                    <div className={cl.main__right__bottom__top__icon}>
                        <GiWaterFountain />
                    </div>
                    <div className={cl.main__right__bottom__top__line}></div>
                </div>
                <div className={cl.main__right__center}>
                    <div className={cl.main__right__center__left}>
                        <img src="/image/type/sculpture/004.jpg" alt="Сухой фонтан ночью" />
                    </div>
                    <div className={cl.main__right__center__center}>
                        
                        <ul>
                            <li>
                                <CiCircleCheck />
                                <span>Абстрактные композиции:</span>
                                <p> Фонтаны, создающие уникальные и необычные визуальные образы</p>
                            </li>
                            <li>
                                <CiCircleCheck />
                                <span>Исторические темы:</span>
                                <p> Фонтаны, воплощающие в себе исторические персонажи</p>
                            </li>
                            <li>
                                <CiCircleCheck />
                                <span>Современное искусство:</span>
                                <p> Представляют собой смелые и инновационные художественные концепции</p>
                            </li>
                            <li>
                                <CiCircleCheck />
                                <span>Тематические фонтаны: </span>
                                <p>Фонтаны, выполненные в виде определенной темы, обогащают пространство уникальными мотивами.</p>
                            </li>
                        </ul>
                    </div>
                    <div className={cl.main__right__center__right}>
                        <img src="/image/type/sculpture/002.jpg" alt="Сухой фонтан ночью" />
                    </div>
                </div>
                <div className={cl.main__right__bottom}>
                    <div className={cl.main__right__bottom__top}>
                        <div className={cl.main__right__bottom__top__line}></div>
                        <div className={cl.main__right__bottom__top__icon}>
                            <GiWaterFountain />
                        </div>
                        <div className={cl.main__right__bottom__top__line}></div>
                    </div>
                    <div className={cl.main__right__bottom__center}>
                        <h5>Скульптурные фонтаны – это не 
                            просто элемент декора, но и технологическое 
                            искусство, призванное подчеркнуть красоту 
                            и гармонию современного городского или 
                            приусадебного ландшафта. С их помощью 
                            удается создать уникальные места, 
                            привлекающие внимание и приносящие радость 
                            глазу и душе. Скульптурные фонтаны становятся 
                            неотъемлемой частью инфраструктуры городов и 
                            общественных пространств, обогащая их 
                            культурными и художественными аспектами.</h5>
                        <p>Однако, несмотря на все эти преимущества, 
                            создание и обслуживание 
                            скульптурных фонтанов требует профессионального 
                            подхода. Инженеры, 
                            художники и технические специалисты работают 
                            в симбиозе, чтобы достичь 
                            оптимального сочетания эстетики и 
                            функциональности. Технологии продолжают 
                            развиваться, открывая новые горизонты для 
                            творчества и инноваций в создании скульптурных фонтанов.</p>  
                        <p>Скульптурные фонтаны придают окружающей 
                            среде изысканный и утонченный вид. Они становятся 
                            центральным элементом ландшафтного дизайна, придавая 
                            уникальный характер и стиль окружающему пространству.
                            Современные технологии позволяют создавать 
                            интерактивные фонтаны с разнообразными эффектами. 
                            Они становятся местом развлечения для посетителей, 
                            особенно детей, предлагая игры с водой и светом.</p>   
                        <p>Сердцем фонтанной системы являются насосы, 
                            ответственные за подачу воды к вершинам 
                            скульптур и создание различных водных 
                            эффектов. Современные фонтаны 
                            обычно используют высокоэффективные 
                            насосы с регулируемой производительностью. 
                            Скульптурные фонтаны оборудованы системами 
                            рециркуляции воды, которые минимизируют потребление 
                            воды и обеспечивают устойчивое использование 
                            водных ресурсов.
                            Вода в фонтане подвергается процессам 
                            фильтрации и очистки, чтобы поддерживать 
                            чистоту воды и уменьшать 
                            необходимость ее замены.</p> 
                        <p>Скульптурные фонтаны, как технически сложные 
                            сооружения, требуют интеграции различных 
                            систем для обеспечения надежной работы 
                            и живописных эффектов. 
                            Взаимодействие между искусством и 
                            технологиями в этой области продолжает 
                            развиваться, открывая новые возможности для 
                            создания уникальных и впечатляющих 
                            водных архитектурных композиций.</p>    
                        <button className={cl.modalbtn} onClick={() => setIsOpenModal(true)}>Оставить заявку</button>  
                    </div>
                    <div className={cl.main__right__bottom__bottom}>
                        <div className={cl.main__right__bottom__bottom__img}>
                            <div className={cl.img}>
                                <div className={cl.img__left}>
                                    <img src="/image/type/sculpture/001.jpg" alt="Скульптурные фонтаны" />
                                </div>
                                <div className={cl.img__right}>
                                    <img src="/image/type/sculpture/006.jpg" alt="Скульптурные фонтаны" />
                                    <img src="/image/type/sculpture/003.jpg" alt="Скульптурные фонтаны" />
                                </div>
                            </div>
                            <div className={cl.img}>
                                <div className={cl.img__left}>
                                    <img src="/image/type/sculpture/004.jpg" alt="Скульптурные фонтаны" />
                                </div>
                                <div className={cl.img__right}>
                                    <img src="/image/type/sculpture/007.jpg" alt="Скульптурные фонтаны" />
                                    <img src="/image/type/sculpture/008.jpg" alt="Скульптурные фонтаны" />
                                </div>
                            </div>
                        </div>
                            
                        <div className={cl.main__right__bottom__bottom__footer}>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
            <ModalWindow 
            isOpen={isOpenModal}
            onClose={() => setIsOpenModal(false)}
         />
        </div>
    )
}

export default Sculptural