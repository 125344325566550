import React, { useState, useEffect } from 'react';
import { MdDone } from 'react-icons/md';
import { Link } from 'react-router-dom';
import cl from './CustomForm.module.css';

const CustomForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        sity: '',
    });

    const [formVisible, setFormVisible] = useState(true);
    const [messageVisible, setMessageVisible] = useState(false);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const [buttonText, setButtonText] = useState('Отправить');

    const handleLabelClick = (e) => {
        document.getElementById(e.target.htmlFor).focus();
    };

    const sendFormTelegram = async (formData) => {
        const TELEGRAM_BOT_TOKEN = '6555110303:AAF9XZparLK32BNezAQvjQL3p6WzEEIS1Kk';
        const TELEGRAM_BOT_ID = "@fnt34"
        const API = `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`;

        const message = `Здравствуйте, \nна сайте: fontan34.ru \n"Строительство фонтанов", \nоставили заявку. \nЗаявка от ${formData.name} \nиз города ${formData.sity} \nномер телефона ${formData.phone} \nС уважением, команда fontan34.ru`;

        try {
            setButtonText('Отправка...');
            const response = await fetch(API, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    chat_id: TELEGRAM_BOT_ID,
                    text: message,
                }),
            });

            if (!response.ok) {
                throw new Error(response.statusText);
            }
        } catch (error) {
            alert('Ошибка при отправке сообщения в Telegram');
            throw new Error('Ошибка при отправке сообщения в Telegram');
        } finally {
            setButtonText('Отправить'); 
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.name || !formData.phone || !formData.sity) {
            alert('Пожалуйста, заполните все обязательные поля.');
            return;
        }

        try {
            await sendFormTelegram(formData);
            setFormVisible(false);
            setMessageVisible(true);
        } catch (error) {
            console.error('Ошибка при отправке формы:', error);
            
        }
    };

    useEffect(() => {
        // Вы можете добавить дополнительные действия, если это необходимо
        return () => {
            // Выполните какие-либо операции очистки, если это необходимо
        };
    }, []); // Пустой массив зависимостей гарантирует выполнение эффекта только после первичной отрисовки

    return (
        <div className={cl.body}>
            {formVisible && (
                <div>
                    <h2>Для расчета стоимости строительства фонтана</h2>
                    <h3>Оставьте заявку</h3>
                    <form className={cl.body__form} onSubmit={handleSubmit}>
                        <div className={cl.body__form__block}>
                            <label htmlFor="name" className={formData.name ? cl.active : ''} onClick={handleLabelClick}>
                                Ваше имя<sup>*</sup>
                            </label>
                            <input type="text" name="name" id="name" value={formData.name} onChange={handleChange} />
                        </div>
                        <div className={cl.body__form__block}>
                            <label htmlFor="phone" className={formData.phone ? cl.active : ''} onClick={handleLabelClick}>
                                Ваш номер телефона<sup>*</sup>
                            </label>
                            <input type="text" name="phone" id="phone" value={formData.phone} onChange={handleChange} />
                        </div>
                        <div className={cl.body__form__block}>
                            <label htmlFor="sity" className={formData.sity ? cl.active : ''} onClick={handleLabelClick}>
                                Из какого Вы города<sup>*</sup>
                            </label>
                            <input type="text" name="sity" id="sity" value={formData.sity} onChange={handleChange} />
                        </div>
                        <div className={cl.boddy__form__block}>
                            <p>
                                <sup>*</sup>Обязательны к заполнению
                            </p>
                            <p>
                                Нажимая кнопку отправить вы соглашаетесь с{' '}
                                <Link to="/info">правилами обработки данных</Link>
                            </p>
                        </div>
                        <button type="submit" disabled={buttonText === 'Отправка...'}>{buttonText}</button>
                    </form>
                </div>
            )}
            {messageVisible && (
                <div className={cl.successMessage}>
                    <div className={cl.successMessage__circle}>
                        <MdDone />
                    </div>
                    <p>Спасибо! Ваша заявка была успешно отправлена.</p>
                    <p>В самое ближайшее время мы с вами свяжемся</p>
                </div>
            )}
        </div>
    );
};

export default CustomForm;
