import { Swiper } from 'swiper/react';
import { Autoplay, EffectCube} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-cube';


const CustomSwiper = ({ effect, spaceBetween, cubeEffect, centeredSlides, autoplay, pagination, children }) => {
    return (
      <Swiper
        
        spaceBetween={spaceBetween}
        centeredSlides={centeredSlides}
        autoplay={autoplay}
        pagination={pagination}
        cubeEffect={cubeEffect}
        effect={effect}
        modules={[EffectCube, Autoplay]}
        className="mySwiper"
      >
        {children}
      </Swiper>
    );
  };
  
  export default CustomSwiper;