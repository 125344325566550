import React, {useState} from 'react'
import Header from '../../../modules/header/Header'
import cl from '../style/Style.module.css'
import AnimateFade from '../../../UI/animateFade/AnimateFade'
import { CiCircleCheck } from "react-icons/ci";
import { GiWaterFountain } from "react-icons/gi";
import Footer from '../../../modules/footer/Footer';
import { ModalWindow } from '../../../UI/modalWindow/ModalWindow';
import { Helmet } from 'react-helmet';

const Cascade = () => {

    const [isOpenModal, setIsOpenModal] = useState(false)

    return (
        <div className={cl.main}>
        <Helmet>
            <title>Каскадные фонтаны | АкваТехПласт | Волгоград</title>
            <meta name="keywords" content="каскадные фонтаны, дизайн, строительство, насосы, техническое обслуживание, водные инсталляции, архитектурные фонтаны, гидравлический расчёт, системы управления водой, каскадный эффект, водострой, инженерия фонтанов" data-react-helmet="true" />
            <meta name="description" content="Исследуйте мир каскадных фонтанов: профессиональное проектирование, точные гидравлические расчеты, эффективные системы насосов и тщательное строительство для создания впечатляющих водных композиций." />
        </Helmet>
            <div className={cl.main__left}>
                <AnimateFade />
                <div className={cl.main__left__title}>
                    <h1>Каскадные фонтаны</h1>
                    
                </div>
            </div>
            <div className={cl.main__right}>
                <AnimateFade />
                <Header />
                <div className={cl.main__right__top}>
                <div className={cl.main__right__top__left}>
                    <h2>Каскадные фонтаны являются одними из наиболее впечатляющих 
                        архитектурных элементов. Эти монументальные сооружения 
                        становятся источником вдохновения, 
                        создавая уникальные водные ландшафты.</h2>
                    <h3>Каскадные фонтаны предоставляют широкий спектр 
                        вариантов для архитекторов и дизайнеров, позволяя 
                        создавать уникальные и впечатляющие водные 
                        композиции. От традиционных стилей до 
                        современных экспериментов, каждый тип 
                        каскадного фонтана приносит свою 
                        особенную атмосферу.</h3>
                        <button className={cl.modalbtn} onClick={() => setIsOpenModal(true)}>Оставить заявку</button>
                </div>
                <div className={cl.main__right__top__right}>
                    <img src="/image/type/cascade/003.jpg" alt="Сухой фонтан ночью" />
                </div>
                </div>
                <div className={cl.main__right__bottom__top}>
                    <div className={cl.main__right__bottom__top__line}></div>
                    <div className={cl.main__right__bottom__top__icon}>
                        <GiWaterFountain />
                    </div>
                    <div className={cl.main__right__bottom__top__line}></div>
                </div>
                <div className={cl.main__right__center}>
                    <div className={cl.main__right__center__left}>
                        <img src="/image/type/cascade/006.jpg" alt="Сухой фонтан ночью" />
                    </div>
                    <div className={cl.main__right__center__center}>
                        
                        <ul>
                            <li>
                                <CiCircleCheck />
                                <span>Террасные Каскады:</span>
                                <p>Фонтаны строятся на террасах или склонах, образуя каскадные ступени.</p>
                            </li>
                            <li>
                                <CiCircleCheck />
                                <span>Вертикальные Каскады:</span>
                                <p>Вертикальные структуры, где вода стекает вниз по плавным поверхностям.</p>
                            </li>
                            <li>
                                <CiCircleCheck />
                                <span> Ступенчатые Каскады:</span>
                                <p>Ступенчатые каскады состоят из набора платформ или ступенек</p>
                            </li>
                            <li>
                                <CiCircleCheck />
                                <span>Абстрактные Конструкции:</span>
                                <p>Представляют собой уникальные формы и геометрии, добавляя современный художественный элемент.</p>
                            </li>
                        </ul>
                    </div>
                    <div className={cl.main__right__center__right}>
                        <img src="/image/type/cascade/008.jpg" alt="Сухой фонтан ночью" />
                    </div>
                </div>
                <div className={cl.main__right__bottom}>
                    <div className={cl.main__right__bottom__top}>
                        <div className={cl.main__right__bottom__top__line}></div>
                        <div className={cl.main__right__bottom__top__icon}>
                            <GiWaterFountain />
                        </div>
                        <div className={cl.main__right__bottom__top__line}></div>
                    </div>
                    <div className={cl.main__right__bottom__center}>
                        <h5>Каскадные фонтаны представляют собой выдающийся пример 
                            технического искусства, совмещающего инженерные 
                            решения и художественный дизайн для 
                            создания великолепных водных композиций.
                            Каскадные фонтаны представляют собой 
                            гармоничное сочетание инженерных технологий и 
                            художественного дизайна. Они не только 
                            украшают окружающее пространство, но и 
                            создают уникальные архитектурные 
                            композиции</h5>
                        <p>Каскадные фонтаны требуют мощных насосов 
                            для поддержания потока воды на различных 
                            уровнях. Системы рециркуляции позволяют 
                            повторно использовать воду, оптимизируя 
                            ее расход и делая конструкцию более 
                            экологически эффективной.
                            Каждый уровень каскада может быть оборудован 
                            регулируемыми форсунками, позволяя контролировать 
                            высоту и направление струй. Дополнительно, использование 
                            дюз и сопловых систем дает возможность 
                            создавать различные водные узоры и формы.</p>  
                        <p>Каскадные фонтаны могут иметь различные структуры, 
                            такие как террасы, вертикальные стены или ступени. 
                            Используются прочные и устойчивые материалы, 
                            такие как бетон, камень или нержавеющая сталь.
                            Важным аспектом строительства является гидроизоляция, 
                            чтобы предотвратить проникновение воды в 
                            структурные элементы и обеспечить 
                            долгий срок службы фонтана.</p>   
                        <p>Строение каскадных фонтанов начинается с 
                            подготовительной работы на месте, 
                            где учитываются география и грунтовые 
                            условия. Инженеры проектируют устойчивые 
                            конструкции, используя бетон, камень или 
                            другие прочные материалы. Для обеспечения 
                            непрерывной циркуляции воды применяются 
                            насосные системы, оснащенные фильтрами и 
                            рециркуляцией. Вода поднимается через 
                            трубы и распределяется на различные уровни, 
                            обеспечивая спектакульный эффект стекающих струй.</p> 
                        <p>Современные каскадные фонтаны оборудованы 
                            автоматизированными системами управления. 
                            Программируемые контроллеры регулируют высоту и 
                            направление струй, а также управляют освещением. 
                            Это позволяет создавать разнообразные водные 
                            пейзажи и динамичные шоу. Некоторые системы 
                            даже предусматривают синхронизацию с 
                            музыкой и звуковыми эффектами.</p>   
                        <button className={cl.modalbtn} onClick={() => setIsOpenModal(true)}>Оставить заявку</button>   
                    </div>
                    <div className={cl.main__right__bottom__bottom}>
                        <div className={cl.main__right__bottom__bottom__img}>
                            <div className={cl.img}>
                                <div className={cl.img__left}>
                                    <img src="/image/type/cascade/002.jpg" alt="Каскадные фонтаны" />
                                </div>
                                <div className={cl.img__right}>
                                    <img src="/image/type/cascade/004.jpg" alt="Каскадные фонтаны" />
                                    <img src="/image/type/cascade/010.jpg" alt="Каскадные фонтаны" />
                                </div>
                            </div>
                            <div className={cl.img}>
                                <div className={cl.img__left}>
                                    <img src="/image/type/cascade/007.jpg" alt="Каскадные фонтаны" />
                                </div>
                                <div className={cl.img__right}>
                                    <img src="/image/type/cascade/009.jpg" alt="Каскадные фонтаны" />
                                    <img src="/image/type/cascade/011.jpg" alt="Каскадные фонтаны" />
                                </div>
                            </div>
                        </div>
                            
                        <div className={cl.main__right__bottom__bottom__footer}>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
            <ModalWindow 
            isOpen={isOpenModal}
            onClose={() => setIsOpenModal(false)}
         />
        </div>
    )
}

export default Cascade