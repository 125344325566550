import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const Cookie = () => {

    return (
      <div className="container">
            <Helmet>
                <title>Политика использования файлов cookie на сайте | АкваТехПласт | Волгоград</title>
            </Helmet>
        <div className="row">
            <div className="col">
                <br />
                <Link to='/'>В начало</Link>
                <br />
                <br />
                <h1>Политика использования файлов cookie на сайте fontan34.ru:</h1>
                <br />
                <br />
                <h5>1. Термины и определения:</h5>
                <br />
                <p>1.1. Под "файлами cookie" понимаются данные, которые веб-сайт 
                    отправляет и хранит на устройстве Пользователя для 
                    оптимизации пользовательского опыта и аналитического анализа.
                </p>
                <br />
                <h5>2. Согласие на использование файлов cookie:</h5>
                <br />
                <p>2.1. Пользователь, посещающий сайт [ваш_сайт], дает согласие 
                    на использование файлов cookie в соответствии с настоящей политикой.
                </p>
                <br />
                <h5>3. Цели использования файлов cookie:</h5>
                <br />
                <p>3.1. Файлы cookie применяются для улучшения функциональности сайта, 
                    предоставления персонализированных услуг и анализа активности 
                    пользователей в соответствии с применимым законодательством.
                </p>
                <br />
                <h5>4. Срок хранения:</h5>
                <br />
                <p>4.1. Файлы cookie могут сохраняться на устройстве Пользователя в 
                    пределах, определенных действующим законодательством Российской Федерации.
                </p>
                <br />
                <h5>5. Управление файлами cookie:</h5>
                <br />
                <p>5.1. Пользователь вправе отклонить использование файлов cookie через 
                    настройки браузера или удалить их после посещения сайта fontan34.ru.
                </p>
                <br />
                <h5>6. Конфиденциальность данных:</h5>
                <br />
                <p>6.1. ООО АкваТехПласт обязуется соблюдать конфиденциальность собранных данных 
                    и не передавать их третьим лицам в соответствии с требованиями статьи 
                    18 Федерального закона "О персональных данных" (152-ФЗ).
                </p>
                <br />
                <h5>7. Правовое обоснование:</h5>
                <br />
                <p>7.1. Настоящая политика основывается на применимом 
                    законодательстве Российской Федерации, включая Федеральный 
                    закон "Об информации, информационных технологиях и о 
                    защите информации" (149-ФЗ) и прочие нормативные акты.
                </p>
                <br />
                <h5>Изменения и дополнения:</h5>
                <br />
                <p>8.1. ООО АкваТехПласт оставляет за собой право вносить изменения
                    и дополнения в настоящую политику. 
                    Изменения вступают в силу с момента 
                    их публикации на сайте.
                </p>
                <br />
                <br />
                <h6>Дата публикации: 25.01.2024</h6>
            </div>
        </div>
      </div>
    )
}

export default Cookie





