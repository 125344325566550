import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom"

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

// const [loading, setLoading] = useState(true);

// useEffect(() => {

//   const timeout = setTimeout(() => {
//     setLoading(false);
//   }, 2500);

//   return () => clearTimeout(timeout);
// }, []);

root.render(
  <BrowserRouter>
        {/* {loading ? (
        <LoadingPage />
      ) : ( */}
        <React.StrictMode>
            <App />
        </React.StrictMode>
        {/* )}   */}
      </BrowserRouter>
      
    
);
