import React, { Component } from 'react'
import { PiTelegramLogoThin, PiPhoneThin, PiAtThin, PiWhatsappLogoThin  } from "react-icons/pi";
import cl from './SocialIcon.module.css'
const SocialIcon = () => {
 
    return (
      <div className={ cl.icon }>
        <div className={cl.icon__box}>
            <a href="tel:+79023815963">
                <PiPhoneThin />
            </a>
            <a href="https://wa.me/79023815963"  target='blank'>
                <PiWhatsappLogoThin />
            </a>
            <a href="mailto:info@fontan34.ru">
                <PiAtThin/>
            </a>
            <a href="https://t.me/fontan_atp">
                <PiTelegramLogoThin/>
            </a>
        </div>
        <div className={cl.icon__address}>
            <h6>Наш адрес:</h6>
            <p>г. Волгоград</p>
            <p>ул. 25 лет Октября, 1, стр. 105 (этаж 2)</p>
        </div>
      </div>
    )
}

export default SocialIcon

